import api from '../services/Api';

// common
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const CHANGE_PATHNAME_STATE = 'CHANGE_PATHNAME_STATE';
export const GET_UPTOKEN = 'GET_UPTOKEN'  //获取七牛的token
export const POST_QINIU = "POST_QINIU" //记录上传七牛
export const GET_PREVIEW = "GET_PREVIEW" //是否预览
export const GET_HEADER_TABKEY = "GET_HEADER_TABKEY" //获取header tabKey
export const GET_SIDEBARKEY = "GET_SIDEBARKEY" //侧边栏
export const GET_LOADING = "GET_LOADING" //全局加载

//通用方法修改属性 路由更改
export function changePathNameState(data) { 
    return { type: CHANGE_PATHNAME_STATE,'data':data}    
}
// this.props.changePathNameState({ property: "pathNameState", state: true });

//获取七牛token 
export function getUptoken() {
    let request = api.soar.getUptoken();
    return { type: GET_UPTOKEN, payload: request,noLoading:true}
}

//是否预览
export function getPreviewSwitch(previewSwitch) {  
    return { type: GET_PREVIEW,previewSwitch:previewSwitch}
}

//获取header tabKey
export function getHeaderTabKey(headerTabKey) {  
    return { type: GET_HEADER_TABKEY,headerTabKey:headerTabKey}
}

//获取侧边栏key
export function getSideBarKey(barKey) {  
    return { type: GET_SIDEBARKEY,barKey:barKey}
}

// 全局加载
export function getLoading(LoadingFiles) {  
    return { type: GET_LOADING,LoadingFiles:LoadingFiles}
}

// auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const GET_SAVE_USER = 'GET_SAVE_USER';
export const GET_USER_INFO = 'GET_USER_INFO'
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT';
export const EDIT_USERINFOR= 'EDIT_USERINFOR'
export const GET_USERINFOR_BYTOKEN = 'GET_USERINFOR_BYTOKEN';

//登录
export function login(mobileArea,mobile,password,ip,appHigh) {
    let request = api.auth.login(mobileArea,mobile,password,ip,appHigh);
    return { type: LOGIN, payload: request, }
}

//注册
export function register(mobileArea,mobile,password,code) {
    let request = api.auth.register(mobileArea,mobile,password,code);
    return { type: REGISTER, payload: request, }
}
//登出
export function logout(history) {
    // history.push('/');
    return { type: LOGOUT }
}
//获取用户信息
export function getUserInfoByToken() {   
    // if (token) {
    //     api.setToken(token);
    // }   
    let request = api.auth.getUserInfoByToken()
    return { type: GET_USER_INFO, payload: request }
}

export function getSaveUser(
    userKey, 
    userName, 
    userAvatar, 
    email, 
    mobile,
    token,
    appHigh,
    app
    ) {
    let request = api.auth.getSaveUserInfor(
        userKey, 
        userName, 
        userAvatar, 
        email, 
        mobile,
        token,
        appHigh,
        app
       );
    return { 
        type: GET_SAVE_USER, 
        payload: request,
        userKey:userKey,
        userName:userName,
        userAvatar:userAvatar,
        email:email,
        mobile:mobile,
        token:token,
     }
}

//修改用户信息
export function editAccounts(profile) {
    let request = api.auth.editAccount(profile);
    return { type: EDIT_ACCOUNT,payload: request, profile: profile,  }
}

//修改用户信息
export function editUserInfo(profile) {    
    return { type: EDIT_USERINFOR, profile: profile }
}

//记录上传到七牛
export function postQinNiu(token,cardKey,url,fileSize) {
    let request = api.common.postQinNiu(token,cardKey,url,fileSize);
    return { 
        type: POST_QINIU, 
        payload: request,       
        token:token,
        fileSize:fileSize
     }
}

//home
export const READYTOREFRESH = 'READYTOREFRESH'  //清除数据[展厅资源]
export const READYTOREFRESH_SCENE = 'READYTOREFRESH_SCENE'  //清除数据
export const CELAER_DETAILS = 'CELAER_DETAILS'  //清除详情
export const CELAER_DATAlIST = 'CELAER_DATAlIST'  //清除数组
export const CLEAR_VIEWDATA = 'CLEAR_VIEWDATA' //清除视图

export const OPEN_NODES_MODEL = 'OPEN_NODES_MODEL' //打开添加节点
export const OPEN_SHIP_MODEL = 'OPEN_SHIP_MODEL' //打开添加关系
export const GET_NODES_INFO = 'GET_NODES_INFO' //获取表单节点信息
export const GET_SHIP_INFO = 'GET_SHIP_INFO' //获取表单关系信息
export const OPEN_MAP = 'OPEN_MAP' //打开地图
export const OPEN_SEARCH_NODES = 'OPEN_SEARCH_NODES' //打开节点搜索弹窗


export const GET_SAVE_MODEL = 'GET_SAVE_MODEL'  //保存场景弹窗
export const ADD_SCENE = 'ADD_SCENE' //新建展厅
export const GET_NODESTABLE = 'GET_NODESTABLE' //节点列表
export const GET_INPROJECT_SCENE_LIST = 'GET_INPROJECT_SCENE_LIST' //获取项目内展厅列表
export const DELETE_SCENE = 'DELETE_SCENE' //删除展厅
export const EDIT_SCENE = 'EDIT_SCENE' //编辑展厅
export const EDIT_SCENE_NAME = 'EDIT_SCENE_NAME' //编辑展厅
export const EDIT_SCENE_ISMODEL = 'EDIT_SCENE_ISMODEL' //编辑展厅是否公开
export const GET_SCENE_DETAILS = 'GET_SCENE_DETAILS' //获取展厅详情
export const EDIT_SCENE_SHARE = 'EDIT_SCENE_SHARE' //公开分享,允许编辑,需要登陆
export const GET_FILE_DETAILS = 'GET_FILE_DETAILS' //获取文件详情
export const EDIT_FILE = 'EDIT_FILE' //编辑文件详情
export const EDIT_FILE_BG = 'EDIT_FILE_BG' //编辑背景

export const GET_SCENE_MODEL = 'GET_SCENE_MODEL' //获取展厅模板
export const COPY_SCENE_MODEL = 'COPY_SCENE_MODEL' //拷贝展厅模板
export const DELETE_SCENE_MODEL = 'DELETE_SCENE_MODEL' //移除模板

export const SHOW_COMMENT = 'SHOW_COMMENT' //展示留言弹窗
export const GET_COMMENT_LIST = 'GET_COMMENT_LIST' //获取留言列表
export const ADD_COMMENT_LIST = 'ADD_COMMENT_LIST' //新增评论
export const DELETE_COMMENT = 'DELETE_COMMENT' //删除评论

export const ORDER_POINT = 'ORDER_POINT' //排序位点
export const GET_OPEN_EDITOR = 'GET_OPEN_EDITOR' //打开编辑弹窗
export const GET_TAGlIST = 'GET_TAGlIST' //标签列表


export const GET_ARTICLE_LIST = 'GET_ARTICLE_LIST' //获取文章列表
export const ADD_ARTICLE = 'ADD_ARTICLE' //新增文章
export const EDIT_ARTICLE = 'EDIT_ARTICLE' //编辑文章
export const DELETE_ARTICLE = 'DELETE_ARTICLE' //删除文章
export const GET_ARTICLE_DETAILS = 'GET_ARTICLE_DETAILS' //获取文章详情
export const CLEAR_ARTICLE = 'CLEAR_ARTICLE' //清空文章
export const GET_ADDARTICLE_STATE = 'GET_ADDARTICLE_STATE' //是否新建文章
export const GET_NEWS_CONTENT = 'GET_NEWS_CONTENT' //获取文章内容

export const GET_PROJECT_LIST = 'GET_PROJECT_LIST' //获取项目列表
export const ADD_PROJECT = 'ADD_PROJECT' //新增项目
export const EDIT_PROJECT = 'EDIT_PROJECT' //编辑项目
export const DELETE_PROJECT = 'DELETE_PROJECT' //删除项目
export const GET_SEARCH_MEMBER = 'GET_SEARCH_MEMBER' //用户检索
export const GET_HISTORY_SHARER = 'GET_HISTORY_SHARER' //历史协作者
export const GET_SHARER_LIST = 'GET_SHARER_LIST' //协作者列表
export const ADD_SHARER = 'ADD_SHARER' //定向分享项目
export const DELETE_SHARER = 'DELETE_SHARER' //删除协作者
export const EDIT_SHARER_ROLE = 'EDIT_SHARER_ROLE' //修改协作者权限
export const EXIT_SHARER = 'EXIT_SHARER' //退出协作

export const GET_NODES_TABLE = 'GET_NODES_TABLE' //获取节点列表
export const ADD_NODES = 'ADD_NODES' //新增节点
export const EDIT_NODES = 'EDIT_NODES' //编辑节点
export const DELETE_NODES = 'DELETE_NODES' //删除节点
export const OPEN_NODES_COVER = 'OPEN_NODES_COVER' //编辑节点图标
export const EDIT_NODES_CELL = 'EDIT_NODES_CELL' //编辑行内节点
export const GET_SCH_NODE = 'GET_SCH_NODE' //搜索节点
export const EDIT_NODES_CENTER = 'EDIT_NODES_CENTER' //设置中心点

export const GET_SHIP_TABLE = 'GET_SHIP_TABLE' //获取关系列表
export const ADD_SHIP = 'ADD_SHIP' //新增关系
export const EDIT_SHIP = 'EDIT_SHIP' //编辑关系
export const DELETE_SHIP = 'DELETE_SHIP' //删除关系
export const GET_VIEW = 'GET_VIEW' //视图
export const GET_NODES_DETAILS = 'GET_NODES_DETAILS' //节点详情
export const GET_SHIP_DETAILS = 'GET_SHIP_DETAILS' //关系详情

export const OPEN_DETAILS = 'OPEN_DETAILS'//打开节点详情
export const OPEN_SHIPDETAILS = 'OPEN_SHIPDETAILS'//打开关系详情
export const EDIT_FILTER = 'EDIT_FILTER' //保存默认过滤项目
export const EDIT_VIEW_LAYOUT = 'EDIT_VIEW_LAYOUT' //保存视图模式
export const PREVIE_VIEW_FILTER = 'PREVIE_VIEW_FILTER' //预览模式筛选条件
export const IMPORT_SHIPS = 'IMPORT_SHIPS' //批量导入关系数据
export const GET_IMG_LIST = 'GET_IMG_LIST' //图库列表
export const EDIT_VIEWDATA_CENTER = 'EDIT_VIEWDATA_CENTER' //修改视图中心点


//清除数据
export function readyToRefresh() {
    return { type: READYTOREFRESH }
}

//清除数据[展厅点位，详情]
export function readyToRefresh_scene() {
    return { type: READYTOREFRESH_SCENE }
}
//清除数组 
export function clearList() {
    return { type: CELAER_DATAlIST }
}

//清除详情 
export function readyToRefresh_Data() {
    return { type: CELAER_DETAILS }
}

//清除视图 
export function clearViewData() {
    return { type: CLEAR_VIEWDATA }
}


//打开添加节点
export function getOpenNodesModel(openNodes) {
    return { type: OPEN_NODES_MODEL,openNodes:openNodes }
}

//打开添加关系 
export function getOpenShipModel(openShip) {
    return { type: OPEN_SHIP_MODEL,openShip:openShip }
}

//获取表单节点信息
export function getNodesInfo(nodesInfo) {
    return { type: GET_NODES_INFO,nodesInfo:nodesInfo }
}

//打开地图
export function getOpenMap(openMap) {
    return { type: OPEN_MAP,openMap:openMap }
}

//打开搜索节点弹窗 
export function getOpenSearchNodes(openSchNodes) {
    return { type: OPEN_SEARCH_NODES,openSchNodes:openSchNodes }
}

//保存场景弹窗
export function getSaveSceneModel(openSceneModel) {
    return { type: GET_SAVE_MODEL,openSceneModel:openSceneModel }
}
//获取项目内列表 
export function getInProjectSceneList(projectKey,page,limit) {  
    let request = api.home.getInProjectSceneList(projectKey,page,limit); 
    return { type: GET_INPROJECT_SCENE_LIST,payload: request,page:page}
}
//新建展厅
export function addScene(param) {  
    let request = api.home.addScene(param); 
    return { type: ADD_SCENE,payload: request}
}

//删除展厅
export function deleteScene(fileKey) {      
    return { type: DELETE_SCENE,fileKey:fileKey}
}

//获取文件详情
export function getFileDetails(token,fileKey) {  
    let request = api.home.getFileDetails(token,fileKey); 
    return { type: GET_FILE_DETAILS,payload: request,fileKey:fileKey}
}
//编辑文件
export function editFileDetails(param) {
    return { type: EDIT_FILE,param:param}
}

//编辑背景 
export function editFileBg(param) {
    let request = api.home.editFileDetails(param); 
    return { type: EDIT_FILE_BG,payload: request,param:param}
}

// //更新展厅配置信息 
// export function editScene(param) {   
//     return { type: EDIT_SCENE,param:param}
// }
export function editSceneName(param) {      
    return { type: EDIT_SCENE_NAME,param:param}
}
//编辑节点图标
export function editSceneCover(param) { 
    return { type: OPEN_NODES_COVER,param: param}
}
//编辑展厅是否公开
export function editSceneIsModel(param) {      
    return { type: EDIT_SCENE_ISMODEL,param:param}
}
//公开分享,允许编辑,需要登陆
export function editSceneShare(token,sceneKey,hasShared,allowEdit,needLogin,projectKey,param) {
    let request = api.home.editSceneShare(token,sceneKey,hasShared,allowEdit,needLogin,projectKey);      
    return { type: EDIT_SCENE_SHARE,payload: request,param:param}
}

//获取展厅详情
export function getSceneDetails(token,sceneKey,projectKey) {  
    let request = api.home.getSceneDetails(token,sceneKey,projectKey); 
    return { type: GET_SCENE_DETAILS,payload: request,}
}

//获取展厅模板列表
export function getSceneModel(token,page,limit,projectKey) {  
    let request = api.home.getSceneModel(token,page,limit,projectKey); 
    return { type: GET_SCENE_MODEL,payload: request,page:page}
}
//移除模板
export function deleteSceneModel(param) {     
    return { type: DELETE_SCENE_MODEL,param:param}
}

//展示留言弹窗
export function getShowComment(showComment) {    
    return { type: SHOW_COMMENT,showComment:showComment}
}
//获取留言列表
export function getCommentList(reportKey,page,limit) {  
    let request = api.soar.getCommentList(reportKey,page,limit); 
    return { type: GET_COMMENT_LIST,payload: request,page:page,noLoading:true}
}
//新增评论  reportKey, content
export function addComment(param) {    
    return { type: ADD_COMMENT_LIST,param:param}
}
//删除评论
export function deleteComment(commentKey) {     
    return { type: DELETE_COMMENT,commentKey:commentKey}
}

//打开编辑弹窗 
export function getOpenEditor(openEditor) {   
    return { type: GET_OPEN_EDITOR,openEditor: openEditor}
}
//标签列表
export function getTagList(isFront) {     
    let request = api.home.getTagList(isFront); 
    return { type: GET_TAGlIST,payload: request}
}


//获取文章列表
export function getArticleList(sceneKey,projectKey) {  
    let request = api.home.getArticleList(sceneKey,projectKey); 
    return { type: GET_ARTICLE_LIST,payload: request}
}
//新增文章 token,sceneKey,title,content,summary,cover,imageList
export function addArticle(param) {    
    return { type: ADD_ARTICLE,param:param}
}
//编辑文章 token,cardKey,title,content,summary,cover,imageList
export function editArticle(param) {  
    return { type: EDIT_ARTICLE,param:param }
}
//删除文章
export function deleteArticle(cardKey) {  
    // let request = api.home.deleteArticle(token,cardKey); 
    return { type: DELETE_ARTICLE,cardKey:cardKey}  
}
//文章详情
export function getArticledetails(cardKey,projectKey) {  
    let request = api.home.getArticledetails(cardKey,projectKey); 
    return { type: GET_ARTICLE_DETAILS,payload: request,noLoading: true}
}
//清除文章
export function clearArticle() {  
    return { type: CLEAR_ARTICLE}
}

//是否新建文章 
export function getAddArticleState(isAddArticle) {  
    return { type: GET_ADDARTICLE_STATE,isAddArticle:isAddArticle}
}

//获取富文本内容 
export function getNewsContent(tipTapContent) {  
    return { type: GET_NEWS_CONTENT,tipTapContent:tipTapContent}
}

//获取项目列表
export function getProject(token,name) {  
    let request = api.home.getProject(token,name); 
    return { type: GET_PROJECT_LIST,payload: request}
}
//新增项目 token,name,cover
export function addProject(param) {    
    return { type: ADD_PROJECT,param:param}
}
//编辑项目 token,projectKey,name,cover
export function editProject(param) {  
    return { type: EDIT_PROJECT,param:param }
}
//删除项目 token,projectKey
export function deleteProject(projectKey) {  
    return { type: DELETE_PROJECT,projectKey:projectKey}  
}
//用户检索
export function getSchMember(token,keyWord) {  
    let request = api.home.getSchMember(token,keyWord); 
    return { type: GET_SEARCH_MEMBER,payload: request}
}
//历史协作者 
export function getHistorySharer(token,limit) {  
    let request = api.home.getHistorySharer(token,limit); 
    return { type: GET_HISTORY_SHARER,payload: request}
}
//项目协作者列表
export function getSharerList(projectKey) {  
    let request = api.home.getSharerList(projectKey); 
    return { type: GET_SHARER_LIST,payload: request}
}
//定向分享项目 token,projectKey,toUser
export function addShareProject(token,projectKey,memberKey) {   
    let request = api.home.addShareProject(token,projectKey,memberKey); 
    return { type: ADD_SHARER,payload:request,memberKey:memberKey,noLoading: true}
}
//修改协作者权限 projectKey, toUser,role
export function editSharerRole(token,projectKey,memberKey,newRole) {  
    let request = api.home.editSharerRole(token,projectKey,memberKey,newRole); 
    return { type: EDIT_SHARER_ROLE,payload:request,memberKey:memberKey,newRole:newRole,noLoading: true }
}
//删除协作者 projectKey, toUser
export function deleteSharer(token,projectKey,memberKey) {  
    let request = api.home.deleteSharer(token,projectKey,memberKey); 
    return { type: DELETE_SHARER,payload:request,projectKey:projectKey,memberKey:memberKey,noLoading: true}  
}

//获取节点表单列表
export function getNodesTable(token,fileKey,name,page,limit) {  
    let request = api.home.getNodesTable(token,fileKey,name,page,limit); 
    return { type: GET_NODES_TABLE,payload: request,page:page}
}
//搜索节点 
export function getSchNodes(token,fileKey,name,page,limit) {  
    let request = api.home.getNodesTable(token,fileKey,name,page,limit); 
    return { type: GET_SCH_NODE,payload: request,page:page,noLoading: true}
}
//新增节点 
export function addNodes(param) {    
    return { type: ADD_NODES,param:param}
}
//编辑节点 
export function editNodes(param) {  
    return { type: EDIT_NODES,param:param }
}
export function editNodesCell(param) {  
    return { type: EDIT_NODES_CELL,param:param }
}
//删除节点
export function deleteNodes(nodeKey) {  
    return { type: DELETE_NODES,nodeKey:nodeKey}  
}
//设置节点中心点
export function editNodesCenter(param) { 
    let request = api.home.editNodes(param); 
    return { type: EDIT_NODES_CENTER,request:request,param:param}  
}

//获取关系表单列表
export function getShipTable(token,name) {  
    let request = api.home.getShipTable(token,name); 
    return { type: GET_SHIP_TABLE,payload: request}
}
//新增关系 
export function addShip(param) {    
    return { type: ADD_SHIP,param:param}
}
//编辑关系
export function editShip(param) {  
    return { type: EDIT_SHIP,param:param }
}
//删除关系
export function deleteShip(relationKey) {  
    return { type: DELETE_SHIP,relationKey:relationKey}  
}
//视图 
export function getViewData(token,fileKey) {  
    let request = api.home.getViewData(token,fileKey); 
    return { type: GET_VIEW,payload: request}
}
//关系详情
export function getNodesDetails(nodeKey) {  
    let request = api.home.getNodesDetails(nodeKey); 
    return { type: GET_NODES_DETAILS,payload: request,noLoading: true}
}
//节点详情
export function getShipDetails(relationKey) {  
    let request = api.home.getShipDetails(relationKey); 
    return { type: GET_SHIP_DETAILS,payload: request,noLoading: true}
}
//打开详情
export function getOpenDetails(openDetails) { 
   return { type: OPEN_DETAILS,openDetails: openDetails}
}
//打开关系详情
export function getOpenShipDetails(openShipDetails) { 
    return { type: OPEN_SHIPDETAILS,openShipDetails: openShipDetails}
}
//保存默认过滤项 
export function editFilter(token,fileKey,filterArr) { 
    let request = api.home.editFilter(token,fileKey,filterArr); 
    return { type: EDIT_FILTER,payload: request,filterArr:filterArr,noLoading: true}
}
//原预览模式条件筛选 
export function previewFilter(filterArr,centerId) {     
    return { type: PREVIE_VIEW_FILTER,filterArr:filterArr,centerId:centerId}
}
//保存视图模式 
export function editViewLayout(token,fileKey,viewType) { 
    let request = api.home.editViewLayout(token,fileKey,viewType); 
    return { type: EDIT_VIEW_LAYOUT,payload: request,viewType:viewType,noLoading: true}
}
//批量导入关系
export function importShips(param) {     
    return { type: IMPORT_SHIPS,param:param}
}
//图片库
export function getBgImgList(page,limit) {
    let request = api.home.getBgImgList(page,limit);
    return { type: GET_IMG_LIST, payload: request,page:page,noLoading: true }
}
//修改视图中心点 
export function editViewDataCenter(nodeKey) {    
    return { type: EDIT_VIEWDATA_CENTER, nodeKey: nodeKey }
}

//shan









