import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link,useHistory} from "react-router-dom";
import { Form, Input, Button,message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./login.css";
import { getUserInfoByToken } from "../../actions/app";
// import util from '../../services/Util'
import api from '../../services/Api'

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [fields, setFields] = useState([
    { name: ["mobile"], value: undefined },
    { name: ["password"], value: undefined },
  ]);

  const mobileArea = '+86'

 //手机号验证
 const validatePhone = (_, value) => {
     // 采用正则表达式进行校验
     const phoneRegex = /^1\d{10}/;
     // 判断 手机号 是否符合 phoneRegex 正则的校验
     if (value && !phoneRegex.test(value)) {
       // 不符合就返回错误
       return Promise.reject('手机号格式错误');
     } else {
       // 符合就返回成功
       return Promise.resolve();
     }
 }

  //表单提交-登录
  const handleSubmit = async(value) => {    
    form.validateFields().then(async (values) => {    
      // console.log("values---", values);
      //mobileArea,mobile,password,ip,appHigh
      let res = await api.auth.login(mobileArea,values.mobile,values.password,'',2)     
      if(res.status === 200){        
        message.success('登录成功')
        window.localStorage.setItem('token',res.data.token)
        dispatch(getUserInfoByToken())  
        history.push('/')
        //表单初始化
        form.resetFields();
      }else{
        message.warning(res.msg);
      }          
    });
  };

  return (
    <div className="login">
      <div className="login-box">
        <div className="login-logo"></div>
        <Form
          className="login-form"
          form={form}
          name="form_in_modal"       
          onFinish={handleSubmit}
          fields={fields}
        >
          <Form.Item
            name="mobile"
            rules={[
              {
                required: true,
                message: "请输入手机号！",                
              },
              { validator: validatePhone }
            ]}
          >
            <Input
              placeholder="输入手机号"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "请输入密码！",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="输入密码"
              type="password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"             
              htmlType="submit"
              className="login-form-button"
            >
              登录
            </Button>
            <div className="login-bottom">
              <span>
                {/* <Link to="/account/verificationCode">使用验证码登录</Link> */}
                <Link className="login-form-forgot" to="/reset">
                  忘记密码
                </Link>
              </span>
              <span>
                新用户? <Link to="/register">立即注册！</Link>
              </span>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default Login;
