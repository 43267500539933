import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Tabs } from "antd";
import {} from "../../actions/app";
// import util from "../../services/Util";
import ListItem from '../common/listItem'

const Farming = () => {
//   const dispatch = useDispatch();
  // const history = useHistory();
  //   let token = util.common.getQueryString("token") || "";
//   const auth = useSelector((state) => state.auth);
//   const user = auth.user;
  const [tabKey, setTabKey] = useState("11"); 

  const tabArr = [
    { lable: "生态资源", key: "11" },
    { lable: "农产品资源", key: "12" },
    { lable: "农产品溯源", key: "13" },
    { lable: "招商信息", key: "14" },
  ]; 

  //切换tab
  const onChange = (key) => {
    setTabKey(key);
    if(key === '14'){

    }
  };

  return (
    <div className="homepage">
      <div className="goverment-box">
        <img src="/image/02.png" alt=""></img>
        <div className="container">
          <div className="works-cnt">
            <div className="buy-cnt">
              <Tabs
                onChange={onChange}
                type="card"
                activeKey={tabKey}
                items={tabArr.map((item, index) => {
                  return {
                    label: `${item.lable}`,
                    key: `${item.key}`,
                    children: (
                      <div className="buy-tab-cnt"> 
                      {
                          tabKey === '14' ?
                          <div>招商信息</div>:
                          <ListItem list={[1,2,3]}></ListItem>
                      }  
                      </div>
                    ),
                  };
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Farming;
