import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col,  } from "antd";
import "./home.css";
import {} from "../../actions/app";
import util from "../../services/Util";



const HomeList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const newsList = [
    { title: "万安天猫优品旗舰店", 
      url:'https://shop566263896.taobao.com' ,
      img:"/image/1.jpg"
    },
    {
      title: "万安淘宝特产企业店",
      url: "https://shop265872200.taobao.com/",  
      img:"/image/2.jpg"  
    },
    {
        title: "万安京东商城",
        url: "https://mall.jd.com/index-12498409.html?from=pc", 
        img:"/image/3.jpg"   
    },
    {
        title: "万安小程序电商",
        // url: "http://ls.nongdao.com/wanan#/computer/home",   
        img:"/image/4.jpg" 
    },
    {
        title: "万安抖音商城",
        // url: "http://ls.nongdao.com/wanan#/computer/home", 
        img:"/image/5.jpg"   
    },
    {
       title: "万安资源管理平台",
       url: "http://ls.nongdao.com/wanan#/computer/home",  
       img:"/image/6.jpg"  
    },
  
  ];

  const goTypeLink=(item)=>{
      if(item.url){
        window.open(item.url)
      }  
      if(item.title === '万安小程序电商'){
         history.push('/p1')  
      } 
      if(item.title === '万安抖音商城'){
        history.push('/p2')  
     }
  }

  return (
    <div className="home-page">
        <div className="top">
            <img src="/image/top.jpg"></img>
        </div> 
        <div className="home-list">
            <div className="container">
            <Row gutter={[15]}>
              {newsList.map((item, index) => {
                return (
                  <Col key={index} xs={12} sm={8} md={6} lg={8} xl={8} xxl={8}>
                    <div className="tagItem" onClick={()=>{goTypeLink(item)}}>
                      <img src={item.img} alt=""></img>
                      <p>{item.title}</p>
                    </div>                    
                  </Col>
                );
              })}
            </Row>
            </div>       
        </div>
    </div>
  );
};
export default HomeList;
