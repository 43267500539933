import api from '../services/Api';
import { message } from 'antd';
import { removeToken } from '../services/token'

import { LOGIN, 
    REGISTER, 
    GET_USER_INFO, 
    LOGOUT,  
    GET_SAVE_USER, 
    EDIT_ACCOUNT,   
    EDIT_USERINFOR,
    GET_USERINFOR_BYTOKEN,   
} from '../actions/app';
const defaultState = {
    token:'',
    user: null,
    userInfro:{},
    userKey:'', 
    userName:window.localStorage.getItem('userName') || '',
    avatar:window.localStorage.getItem('avatar') || '../image/ava.png',
};

const auth = (state = defaultState, action) => {
    switch (action.type) {
        // 登陆
        case LOGIN:
            if (!action.error) {            
                window.location.href = window.location.origin 
                console.log('登录login',action.payload);             
                return {
                    ...state,
                    user: action.payload.data,
                };
            } else {
                return state;
            }
        // 登出
        case LOGOUT:
            if (window.QC) {
                window.QC.Login.signOut();
            }
            window.sessionStorage.clear();           
            window.localStorage.clear();
            api.setToken('');
            removeToken()
            return {
                ...state,
                user: null,
                userInfro:{}
            }
        //注册
        case REGISTER:
            if (!action.error) {
                // console.log('注册',action.payload);
                return {
                    ...state,
                    user: action.payload.data,
                };
            } else {
                return state;
            }       
        // 获取用户信息
        case GET_USER_INFO:
            if (!action.error) {                
                // console.log('获取用户user',action.payload);
                window.localStorage.setItem('token',action.payload.data.token)
                return {
                    ...state,
                    user: action.payload.data,
                };
            }else{               
                if (window.QC) window.QC.Login.signOut();
                window.localStorage.clear();
                api.setToken('');
                removeToken()        
                return state;  
            }                   
        // 保存用户信息       
        case GET_SAVE_USER:
            if (!action.error) {  
                console.log('保存用户信息');             
                let userInfor={
                    'userKey':action.userKey,
                    'userName':action.userName,
                    'userAvatar':action.userAvatar,
                    'email':action.email,
                    'mobile':action.mobile
                } 
                window.localStorage.setItem('token',action.token)
                // console.log('userInfor',userInfor);                    
                return {
                    ...state,
                    user:userInfor,                   
                    token:action.token                  
                };
            }
            else {
                console.log('保存用户信息');
                if (window.QC) 
                console.log('保存用户信息---第三方登录');
                window.QC.Login.signOut();
                window.localStorage.clear();
                api.setToken('');
                removeToken()
                // action.history.push('/home');
                return state;
            }
        //获取mall用户信息
        case GET_USERINFOR_BYTOKEN:            
            if (!action.error) {                                           
                return {
                    ...state,
                    user:action.payload.data,                   
                    token:action.payload.data.token                  
                };
            }else{
                if (window.QC) window.QC.Login.signOut();
                window.localStorage.clear();
                api.setToken('');
                removeToken()
                // action.history.push('/home');
                return state;                    
            }      
                
        //修改用户信息 
        case EDIT_ACCOUNT: 
          if (!action.error) {
            message.warning('修改用户信息成功')
            // console.log('action.profile',action.profile);
            let user = JSON.parse(JSON.stringify(state.user));
            user.userAvatar = action.profile.avatar
            user.userName = action.profile.userName
            user.email = action.profile.email                     
            return {
                ...state,
                user: user,
            };
          }else{
              return state
          }           
        //修改用户信息
        case EDIT_USERINFOR: 
            // console.log('action.profile',action.profile);
            let user = JSON.parse(JSON.stringify(state.user));
            for(let key in action.profile){               
                user[key] = action.profile[key]
            }           
            return {
                ...state,
                user: user,
            };
        default:
            return state;
    }
};

export default auth;
