import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import { getUserInfoByToken } from "./actions/app";
// import api from "./services/Api";
// import util from "./services/Util";
import zhCN from "antd/lib/locale/zh_CN";
import routers from "./router/index";
import Header from "./components/common/header";

const AppWrapper = () => {
  const dispatch = useDispatch();
  // const {pathname} = useLocation()
  let token = window.localStorage.getItem("token") ||"";
  const auth = useSelector((state) => state.auth);
  const user = auth.user;
  
  //有token，并且user=null，根据token获取用户信息
  useEffect(() => {
    if (token && token !== "underfind" && token !== null) { 
      dispatch(getUserInfoByToken())     
    } else {
      //没有token
      // console.log('没有token');
    }
  }, [token]);

  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        "@primary-color": "#07BE51",
      }}
    >
      <Router>
        {/* {
           window.location.pathname === '/preview' || window.location.pathname === '/edit' ?
           null : <Header></Header>
         }  */}
         <div className="app">
           {/* <Header></Header> */}
           {/* <Switch>
            {routers.map((router, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={router.path}
                  component={router.component}
                ></Route>
              );
            })}
          </Switch> */}
         <div className="app-content">          
          <Switch>
            {routers.map((router, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={router.path}
                  component={router.component}
                ></Route>
              );
            })}
          </Switch>
        </div>
        </div>
      </Router>     
    </ConfigProvider>
  );
};

export default AppWrapper;
