import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {  } from "antd";
import "./home.css";
import {} from "../../actions/app";

const HomePic = () => {
  // const dispatch = useDispatch();
  // const history = useHistory();

  return (
    <div className="home-page">
        <img src="/image/pic2.jpg"></img>
    </div>
  );
};
export default HomePic;
