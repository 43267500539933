import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"
import {  } from 'antd';
// import './goverment.css'
import { } from '../../actions/app'
import { SwapRightOutlined } from '@ant-design/icons';
import util from '../../services/Util'
import moment from "moment";
import "moment/locale/zh-cn";

const ListItem = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let token = util.common.getQueryString("token") || "";
    const {list} = props  
    let linkType = util.common.getQueryString("linkType") || "";
    // const home = useSelector((state) => state.home);
    // const developerList = home.developerList 

    return (
        <div className="goverment-list">
        {
            list.map((item,index)=>{
                return(
                <div className="goverment-item" key={index}
                onClick={()=>history.push('/newsDetails?detailsKey=11&type='+linkType)}
                >
                    <div className="left">
                        <div className="time">{moment().format("YYYY/MM/DD")}</div>
                        <div className="pic"></div>
                        <div className="goverment-title">廉政学习｜两山集团召开加强集体员工廉洁自律工作会议两山集团召开加强集体员工廉洁自律工作会议两山集团召开加强集体员工廉洁自律工作会议</div>
                    </div>
                    <div className="right">
                        <SwapRightOutlined className="more"/>
                    </div>
                </div>
                )
            })
        }
    </div>
    );
};
export default (ListItem);