import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"
import {  } from 'antd';
import { SwapRightOutlined } from '@ant-design/icons';
import { } from '../../actions/app'
import util from '../../services/Util'
import moment from "moment";
import "moment/locale/zh-cn";


const News = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let token = util.common.getQueryString("token") || "";
    const auth = useSelector((state) => state.auth);
    const user = auth.user;
    // const home = useSelector((state) => state.home);
    // const developerList = home.developerList 

    return (
        <div className="homepage">     
        <div className="goverment-box">
            <div className="container">              
               <div className="goverment-banner"></div>
               <div className="goverment-list">
                  {
                      [1,2].map((item,index)=>{
                          return(
                             <div className="goverment-item" key={index} 
                             onClick={()=>history.push('/newsDetails?detailsKey=11&type=2')}
                             >
                                <div className="left">
                                    <div className="time">{moment().format("YYYY-MM-DD")}</div>
                                    <div className="pic"></div>
                                    <div className="goverment-title">万安两山集团3.4-3.10一周工作动态</div>
                                </div>
                                <div className="right">
                                  <SwapRightOutlined className="more"/>
                                </div>
                             </div>
                          )
                      })
                  }
               </div>
            </div>              
        </div>         
     </div>
    );
};
export default (News);