import api from './services/Api';
import { message } from 'antd';

import {
    ASYNC_START,
    ASYNC_END,
    LOGIN,
    LOGOUT,
    REGISTER,
    GET_SAVE_USER, 
}
    from './actions/app';

const promiseMiddleware = store => next => action => {
    if (isPromise(action.payload)) {
        store.dispatch({ type: ASYNC_START, noLoading: action.noLoading, subtype: action.type });

        const currentView = store.getState().viewChangeCounter;
        const skipTracking = action.skipTracking;

        action.payload.then(            
            res => {               
                const currentState = store.getState();
                if (!skipTracking && currentState.viewChangeCounter !== currentView) {
                    return;
                }
                if (parseInt(res.statusCode, 10) === 200 || res.status === 200) {
                    // console.log('res',res);
                    action.payload = res;
                    store.dispatch({ type: ASYNC_END, promise: action.payload });
                    store.dispatch(action);
                }
                //验证token失效
                else if(res.error_code === 10008){                  
                    alert("验证token")
                    // window.location.href = window.location.origin + '/my'
                }
                else if(res.statusCode === "201" || res.status === 201){
                    action.error = true;
                    action.payload = res;
                    message.info(res.msg);
                    store.dispatch({ type: ASYNC_END });
                    store.dispatch(action);
                }
                else {                                    
                    action.error = true;
                    action.payload = res;
                    if(res.statusCode === "701" || res.status === 701){                   
                        message.info('登录过期！'); 
                        window.localStorage.clear(); 
                        window.location.href = window.location.origin
                        // window.location.reload();
                        // window.location.href = `/account/login${window.location.search}`;
                    }else{
                        // message.info(res.msg);                        
                    }                   
                    store.dispatch({ type: ASYNC_END });
                    store.dispatch(action);
                }
            },
        );
        return;
    }
    next(action);
};

const localStorageMiddleware = store => next => action => {
    if (action.type === LOGIN) {
        if (!action.error) {
            window.localStorage.setItem('TOKEN', action.payload.data.token);
            api.setToken(action.payload.data.token);
        }
        
    } else if (action.type === REGISTER) {
        window.localStorage.setItem('TOKEN', action.payload.data.token);
        api.setToken(action.payload.data.token);
    }
    else if (action.type === LOGOUT) {
        window.sessionStorage.clear();
        api.setToken('');
    }
    else if (action.type === GET_SAVE_USER){
        if (!action.error) {
            window.localStorage.setItem('TOKEN',window.localStorage.getItem('token'));
            api.setToken(window.localStorage.getItem('token'));
        }        
    }
    next(action);
};

function isPromise(v) {
    return v && typeof v.then === 'function';
}


export { promiseMiddleware, localStorageMiddleware };