import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"
import {  } from 'antd';
import './goverment.css'
import { } from '../../actions/app'
import { SwapRightOutlined } from '@ant-design/icons';
import util from '../../services/Util'
import moment from "moment";
import "moment/locale/zh-cn";

const Goverment = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let token = util.common.getQueryString("token") || "";
    const auth = useSelector((state) => state.auth);
    const user = auth.user;
    // const home = useSelector((state) => state.home);
    // const developerList = home.developerList 

    return (
        <div className="homepage">     
           <div className="goverment-box">
               <div className="container">
                  <h2 className="h2-title">廉政学习</h2>
                  <div className="goverment-banner"></div>
                  <div className="goverment-list">
                     {
                         [1,2].map((item,index)=>{
                             return(
                                <div className="goverment-item" key={index}
                                onClick={()=>history.push('/worksDetails?detailsKey=11&type=1')}
                                >
                                   <div className="left">
                                       <div className="time">{moment().format("YYYY/MM/DD")}</div>
                                       <div className="pic"></div>
                                       <div className="goverment-title">廉政学习｜两山集团召开加强集体员工廉洁自律工作会议两山集团召开加强集体员工廉洁自律工作会议两山集团召开加强集体员工廉洁自律工作会议</div>
                                   </div>
                                   <div className="right">
                                     <SwapRightOutlined className="more"/>
                                   </div>
                                </div>
                             )
                         })
                     }
                  </div>
               </div>              
           </div>         
        </div>
    );
};
export default (Goverment);