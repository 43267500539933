// import html2canvas from 'html2canvas';
const common = {
    // 判断当前是否在小程序内
    isMiniProgram() {
        return window.__wxjs_environment === 'miniprogram';
    },
    // 判断当前是否是手机端
    isMobile() {
        return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    },
    //判断是否是在微信浏览器中
    isWeiXins:function(){ 
        var ua = window.navigator.userAgent.toLowerCase();
        if(ua.match(/MicroMessenger/i) === 'micromessenger'){
          return true;
          }else{
          return false;
        }
    },
    // 判断是安卓系统还是ios系统
    isAndroidOrIos(){
        var u = navigator.userAgent;
        var phoneType = '';
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);       
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端 
        if(isiOS){
            phoneType = 'isIos' 
        }else if(isAndroid){
            phoneType = 'isAndroid'
        }
        return phoneType 
    },
    // 获取url参数
    getQueryString(name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return null;
    },
    getParamString(paraPart,name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = paraPart.substr(1).match(reg);
    //    if (r != null) return unescape(r[2]); return null;
       if (r != null) return decodeURI(r[2]); return null;
    },
    getRequest() {   
        var url = window.location.search; //获取url中"?"符后的字串  
        // console.log(url); 
        // var theRequest = new Object();  
        var theRequest = {} 
        if (url.indexOf("?") !== -1) {   
           var str = url.substr(1);   
           var strs = str.split("&");   
           for(var i = 0; i < strs.length; i ++) {   
               //就是这句的问题
              theRequest[strs[i].split("=")[0]]=decodeURI(strs[i].split("=")[1]); 
              //之前用了unescape()
              //才会出现乱码  
           }   
        }   
        return theRequest;   
     },
  
    //识别电脑系统并返回
    getUserAgent:function(){
        var agent = navigator.userAgent.toLowerCase();
        var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
        if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
            return 'windows';
        }
        if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
            return 'windows';
          }
        if(isMac){
            return 'mac';
        }
    },
    unionArray(arr){
        let tempArr = this.slice() ;
        arr.forEach(v => {
            !tempArr.includes(v) && tempArr.push(v)
        })  
        return tempArr
    }, 
   //判断是否是json对象
    isJsonString(str) {
        try {
            if (typeof JSON.parse(str) == "object") {
                return true;
            }
        } catch(e) {
        }
        return false;
    },
  // 生成标识符
    guid(len, radix) {
        var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
        var uuid = [], i;
        radix = radix || chars.length;

        if (len) {
            // Compact form
            for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
        } else {
            // rfc4122, version 4 form
            var r;

            // rfc4122 requires these characters
            uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
            uuid[14] = '4';

            // Fill in random data.  At i==19 set the high bits of clock sequence as
            // per rfc4122, sec. 4.1.5
            for (i = 0; i < 36; i++) {
                if (!uuid[i]) {
                    r = 0 | Math.random() * 16;
                    uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
                }
            }
        }

        return uuid.join('');
    }, 
    scrollFunc() {
        var scrollAction = {x: 'undefined', y: 'undefined'}, scrollDirection;
        if (typeof scrollAction.x == 'undefined') {
            scrollAction.x = window.pageXOffset;
            scrollAction.y = window.pageYOffset;
        }
        var diffX = scrollAction.x - window.pageXOffset;
        var diffY = scrollAction.y - window.pageYOffset;
        if (diffX < 0) {
            // Scroll right
            scrollDirection = 'right';
            
        } else if (diffX > 0) {
            // Scroll left
            scrollDirection = 'left';
        } else if (diffY < 0) {
            // Scroll down
            scrollDirection = 'down';
            
        } else if (diffY > 0) {
            // Scroll up
            scrollDirection = 'up';
        } else {
            // First scroll event
        }         
        scrollAction.x = window.pageXOffset;
        scrollAction.y = window.pageYOffset;
        return scrollDirection
    }, 
  
    //判断链接是否是有效的图片链接
    checkImgExists(imgurl) { 
        return new Promise(function(resolve, reject) {
            var ImgObj = new Image();
            ImgObj.src = imgurl;
            ImgObj.onload = function(res) {
            resolve(res);
            }
            ImgObj.onerror = function(err) {
            reject(err)
            }
        })
   },
   //判断输入是否是网址
   judgeIsUrl(webSite){
    var str = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i');   
           
    if(!str.test(webSite)){   
        // console.log("请输入正确的url！");
        return false
    }else{
        return true
    }
   },
   //排序
   compareNum(property,type){ 
    return function(a,b){
        var value1 = a[property];
        var value2 = b[property];
        if(type === 1){ //正序 从低到高
            return value1 - value2;
        }else if(type === 2){ //逆序 从高到低
            return value2 - value1;
        }else if(type === 4){ //分数逆序-从高到低
            return value2 - value1;
        }
    }
   },
   //乱序
   shuffle(arr) {
    var len = arr.length;
    for (var i = 0; i < len - 1; i++) {
        var index = parseInt(Math.random() * (len - i));
        var temp = arr[index];
        arr[index] = arr[len - i - 1];
        arr[len - i - 1] = temp;
    }
    return arr;
   },
   //判断是否为图片后缀名
   isPicture(suffix){
     if(suffix!==""){
        suffix = suffix.toLocaleLowerCase();
        // 图片格式
        const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
        // 进行图片匹配
        var result = imglist.find(item => item === suffix);
        if (result) { //是图片后缀
          return true
        }else{ //不是图片后缀
          return false
        }
     }
   },
   //改变rgb颜色(颜色变淡，最深色是红色【255,0,0】)
   changeRgb(score){     
       let [r, g, b] = [255, 255, 255];     
       let count = 26 //次数
       let n = 0
       if((score / count > 2)){
          r = 255
          g = 0
          b = 0           
       }else{
        if((score / count < 1)  || (score / count === 1) )  {
            b = b - (score - count * n) *10
            if(b === -5){
                b = 0
            }
        }
        if((score / count < 2 && (score / count > 1))  || (score / count === 2) )  {
            n = 1
            g = g - (score - count * n) *10
            b = 0
            if(g === -5){
              g = 0
            }
        } 
        // if((score / count < 3 && (score / count > 2))  || (score / count == 3) )  {
        //      n = 2
        //      r = r - (score - count * n) *10
        //      g = 0
        //      b = 0
        //      if(r == -5){
        //        r = 0
        //      }
        // }
       }
       const colorRgb = `rgb(${r},${g},${b})`
       return colorRgb       
   },
   //判断pc端和移动端
   IsPC() { 
    var flag = false;
    if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      flag = false;
   } else{
      flag = true;
   } 
    return flag;
  },
  //获取30天前的每一天的日期
  getLimitDayBefore(limit) {
    var thrityMonth = [];
    let newMonth =[]   
    for (var i = 0; i < limit; i++) {
      thrityMonth.unshift(new Date(new Date().setDate(new Date().getDate() - i)).toLocaleDateString().split('/').join('-'))
    } 
    thrityMonth.forEach(item=>{
       let year,month,day
       var s = item.split("-")
       year = s[0]
       month = s[1] < 10 ?  "0"+s[1]:s[1] 
       day = s[2] < 10 ? "0"+s[2]:s[2]
       item = year +"-" + month + "-" + day       
       newMonth.push(item)         
    })   
    return newMonth
  }, 
  //获取30天后每一天日期
  get_30_dayAfter(){
    var dateList = [];
    let startDate = new Date();
    let endDate = new Date();
    var year = new Date().getFullYear();
    endDate.setDate(startDate.getDate() + 30);
    while ((endDate.getTime() - startDate.getTime()) >= 0) {
        let month = (startDate.getMonth() + 1).toString().length === 1 ? "0" + (startDate.getMonth() + 1).toString() : (
            startDate.getMonth() + 1);
        let day = startDate.getDate().toString().length === 1 ? "0" + startDate.getDate() : startDate.getDate();
        dateList.push(year + '-'+ month + "-" + day);
        startDate.setDate(startDate.getDate() + 1);
    }
  },
  
  formatSeconds(value){
    //   console.log('value',value);
    var theTime = parseInt(value);// 需要转换的时间秒
    var theTime1 = 0;// 分
    var theTime2 = 0;// 小时
    var theTime3 = 0;// 天
    if(theTime > 60) { 
    theTime1 = parseInt(theTime/60); 
    theTime = parseInt(theTime%60); 
        if(theTime1 > 60) { 
        theTime2 = parseInt(theTime1/60); 
        theTime1 = parseInt(theTime1%60); 
            if(theTime2 > 24){
                //大于24小时
                theTime3 = parseInt(theTime2/24);
                theTime2 = parseInt(theTime2%24);
            }
        } 
    } 
    var result = '';
    if(theTime > 0){
        result = ""+parseInt(theTime)+"s";
    }
    if(theTime1 > 0) { 
        result = ""+parseInt(theTime1)+"m"+result; 
    } 
    if(theTime2 > 0) { 
        result = ""+parseInt(theTime2)+"h"+result; 
    } 
    if(theTime3 > 0) { 
        result = ""+parseInt(theTime3)+"t"+result; 
    }
    return result;   
  },
  //节流
  throttle(fn,delay){
    let valid = true
    return function() {
       if(!valid){
           //休息时间 暂不接客
           return false 
       }
       // 工作时间，执行函数并且在间隔期内把状态位设为无效
        valid = false
        setTimeout(() => {
            fn()
            valid = true;
        }, delay)
    }
  }, 
  //提取市乡镇信息
  addressToTree(address){
	let districtssheng = address.province_list;
	let districtsShi = address.city_list;
	let districtsQu = address.county_list;
	let newArrSheng = [];
	for(var i in districtssheng){ 
		// 第一层循环遍历出省份
        let districtsShengObj = {}
        districtsShengObj.code = i
		districtsShengObj.value = districtssheng[i]
		districtsShengObj.label = districtssheng[i]
		let newArrShi = []
		for(var s in districtsShi){
			//第二层循环出市
		    if(i.substring(0,2) === s.substring(0,2) ){
			 // 以城市编码的前两位作为匹配项
                let shiobj={};
                shiobj.code = s
				shiobj.value = districtsShi[s]
				shiobj.label = districtsShi[s]
				let newArrQU = []
				for(var q in districtsQu){
					//第三层循环遍历出区
					if(s.substring(0,4) ==q.substring(0,4) ){
					  //市与区以编码的前四位作为匹配
                        let quobj={};
                        quobj.code = q
					    quobj.value = districtsQu[q]
					    quobj.label = districtsQu[q]
					    newArrQU.push(quobj);
					    shiobj.children=newArrQU;
					}
				 }
				newArrShi.push(shiobj);
				districtsShengObj.children = newArrShi;
				//添加到所需的对象里
			}
		}
		newArrSheng.push(districtsShengObj)  // 最后添加到数组里
    }
    // console.log('newArrSheng',newArrSheng)
    return newArrSheng;
   },  
 
  
  //搜索模糊匹配
  search(list,keyword) {
    const pattern = new RegExp(keyword, 'i'); 
    return list.filter(item => {
        return pattern.test(item.content);
    });
  },
  //对比两个数组取出不同
  getInfoArr(arr1, arr2){
    let arr = []
    for(let i = 0; i < arr1.length; i++){
      let obj = arr1[i];
      let isExist = false;
      for(let j = 0; j < arr2.length; j++){
        let aj = arr2[j];
          if(obj == aj){
              isExist = true;
              break;
          }
      }
      if(!isExist){
        arr.push(obj);
      }
    }
    return arr;
  },
  //判断是否是平年还是闰年 方法 返回 ‘true ’为闰年
  isLeapYear(year) {
    return (year % 4 == 0) && (year % 100 != 0 || year % 400 == 0);    
  },
  judgeYearDay(year,month) { // 判断当前年的当前月有多少天
    let monthDayTotal = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31] //默认闰年
    let nowMonthIdx = month - 1 //当前月的下标
    if (year % 4 == 0 && year % 100 != 0 || year % 400 == 0) { //闰年
        return monthDayTotal[nowMonthIdx] //当前月的天数
    } else { //平年
        monthDayTotal = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
        return monthDayTotal[nowMonthIdx] //当前月的天数
    }
  },
  //根据年月日判断是周几
  judgeWeek(datas){
    const dates = datas.split("-")
    // 创建一个新的Date对象，注意月份是从0开始的，所以要减去1
    var date = new Date(dates[0], dates[1] - 1, dates[2]);    
    // 获取星期几，返回值是0（星期日）到6（星期六）
    var week = date.getDay();    
    // 创建一个数组来存储星期几的名称
    var weekArr = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];    
    // 返回对应的星期几名称
    return weekArr[week];
  } 
 
}
//eslint-disable-next-line
export default {
    common
};