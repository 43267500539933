import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"
import {  } from 'antd';
import { } from '../../actions/app'
import util from '../../services/Util'


const EnterPrise = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let token = util.common.getQueryString("token") || "";
    const auth = useSelector((state) => state.auth);   
    // const home = useSelector((state) => state.home);
    // const developerList = home.developerList 

    return (
        <div className="homepage">     
        <div className="goverment-box">
        <img src="/image/02.png" alt=''></img>
            <div className="container">
                <h2 className="h2-title">万安县两山集团公司</h2>  
                <p>万安县“两山公司”为深入践行习近平总书记两山理论，聚焦“走在前、勇争先、善作为”目标要求和锚定“三区”战略，打好“十大攻坚战”工作部署，建立健全生态价值实现的国有运营平台，积极探索打造可复制可推广的两山转化模式。  

集团公司于2022年4月成立，注册资本3亿元，隶属于县国资办。集团公司下设六大产业板块，分别是农业、林业、水利、文旅、交通、数字经济。对应6大产业板块，下设28家实体经营企业，涉及文旅、餐饮、生态鱼养殖、富硒农产品、国土绿化和水利建设等行业。截至2月底，集团规上企业4家，累计完成营收约1.69亿元，实现利润约1227万元。</p>              
            </div>              
        </div>         
     </div>
    );
};
export default (EnterPrise);