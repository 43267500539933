import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Row, Col, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./login.css";
import {} from "../../actions/app";
import Loading from "../common/Loading";
// import util from '../../services/Util'
import api from "../../services/Api";

let timeChange;

const ResetPassword = () => {
  // const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [fields, setFields] = useState([
    { name: ["mobile"], value: undefined },
    { name: ["password"], value: undefined },
    { name: ["confirm"], value: undefined },
    { name: ["code"], value: undefined },
  ]);

  const [time, setTime] = useState(60);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnContent, setBtnContent] = useState("获取验证码");
  const mobileArea = "+86";

  useEffect(() => {
    clearInterval(timeChange);
    return () => clearInterval(timeChange);
  }, []);

  useEffect(() => {
    if (time > 0 && time < 60) {
      setBtnContent(`${time}s后重发`);
    } else {
      clearInterval(timeChange);
      setBtnDisabled(false);
      setTime(60);
      setBtnContent("获取验证码");
    }
  }, [time]);

  //手机号验证
  const validatePhone = (_, value) => {
    // 采用正则表达式进行校验
    const phoneRegex = /^1\d{10}/;
    // 判断 手机号 是否符合 phoneRegex 正则的校验
    if (value && !phoneRegex.test(value)) {
      // 不符合就返回错误
      return Promise.reject("手机号格式错误");
    } else {
      // 符合就返回成功
      return Promise.resolve();
    }
  };

  //验证密码
  //  const validateToNextPassword = (_, value) => {
  //     if (value) {
  //         form.validateFields(['confirm'], { force: true });
  //     }
  //     return Promise.resolve()
  //  };

  //再次验证密码
  const compareToFirstPassword = (_, value) => {
    if (value && value !== form.getFieldValue("password")) {
      return Promise.reject("两次输入的密码不同！");
    } else {
      return Promise.resolve();
    }
  };

  // 点击验证码按钮
  const getPhoneCaptcha = async () => {
    const mobile = form.getFieldValue("mobile");
    if (!mobile) {
      message.warning("请输入手机号！");
      return;
    }
    Loading.open("请稍后...");
    //mobileArea, mobile, source
    let res = await api.auth.getCode(mobileArea, mobile, 3);
    if (res.status === 200) {
      // 注意，不要使用 setTime(t-1)  闭包问题会导致time一直为59
      timeChange = setInterval(() => setTime((t) => --t), 1000);
      setBtnDisabled(true);
      Loading.close();
    } else {
      message.warning(res.msg);
      Loading.close();
    }
  };

  //表单提交-忘记密码
  const handleSubmit = async (value) => {
    form.validateFields().then(async (values) => {
      //   const _key = form.getFieldValue("_id");
      console.log("values---", values);
      let res = await api.auth.resetPwd(
        mobileArea,
        values.mobile,         
        values.code,
        values.password
      );
      if (res.status === 200) {
        message.success("重置密码成功");
        history.push("/login");
        //表单初始化
        form.resetFields();
      } else {
        message.warning(res.msg);
      }
    });
  };

  return (
    <div className="login">
      <div className="login-box">
        <div className="login-logo"></div>
        <Form
          className="login-form"
          form={form}
          name="form_in_modal"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="mobile"
            rules={[
              {
                required: true,
                message: "请输入手机号！",
              },
              { validator: validatePhone },
            ]}
          >
            <Input
              placeholder="输入手机号"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            // hasFeedback
            name="password"
            rules={[
              {
                required: true,
                message: "请输入密码！",
              },
              //   { validator: validateToNextPassword }
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="输入密码"
              type="password"
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            name="confirm"
            rules={[
              {
                required: true,
                message: "请输入密码！",
              },
              { validator: compareToFirstPassword },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="请确认密码"
              type="password"
            />
          </Form.Item>
          <Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item name="code">
                  <Input placeholder="请输入验证码" />
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* {
                        count === 0 ? <Button 
                        onClick={()=>getCode()}>获取验证码</Button> : <span>{`已发送验证码 ${count}秒`}</span>
                    } */}
                <Button disabled={btnDisabled} onClick={getPhoneCaptcha}>
                  {btnContent}
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              重置密码
            </Button>
            <Link to="/login">立即登录</Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default ResetPassword;
