import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {  } from "antd";
import "./buy.css";
import {} from "../../actions/app";
// import util from "../../services/Util";
import moment from "moment";
import "moment/locale/zh-cn";

const BuyItem = () => {
  const dispatch = useDispatch();
//   const history = useHistory();

  return (
    <div className="buy-list">
        {
            [1,2,3,4].map((item,index)=>{
                return(
                    <div key={index} className="buy-list-item">
                        <div className="buy-left">
                            <div>万安两山有限公司xxx分公司 xxx项目 公开招标报名公告</div>
                            <div className="buy-details">
                              <div>发布时间：{moment().format("YYYY/MM/DD")}</div>
                              <div className="mgl10">采购人：万安两山有限公司xxxx分公司</div>
                            </div>
                        </div>
                        <div className="buy-right">
                            状态
                        </div>
                    </div>
                )
            })
        }                          
    </div>
  );
};
export default BuyItem;
