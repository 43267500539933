import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory,Link } from "react-router-dom"
import {  } from 'antd';
import './news.css'
import { RightOutlined } from '@ant-design/icons';
import { } from '../../actions/app'
import util from '../../services/Util'
import moment from "moment";
import "moment/locale/zh-cn";
import Tiptap from './editor'


const NewDetails = () => {                            
    const dispatch = useDispatch();
    const history = useHistory();                                                   
    let token = window.localStorage.getItem('token') || "";
    let detailsKey = util.common.getQueryString("detailsKey") || "";
    let type = util.common.getQueryString("type") || ""
    const auth = useSelector((state) => state.auth);
    const user = auth.user; 
    const libkObj={
        "1":{"link":"/goverment","name":"廉政学习"},
        "2":{"link":"/news","name":"新闻动态"},
        "3":{"link":"/farming?linkType=3","name":"两山农业"},
        "4":{"link":"/forestry?linkType=4","name":"两山林业"},
        "5":{"link":"/water?linkType=5","name":"两山水利"},
        "6":{"link":"/tour?linkType=6","name":"两山文旅"},
        "7":{"link":"/traffic?linkType=7","name":"两山交通"},
        "8":{"link":"/economy?linkType=8","name":"万安数字经济"},
    }
    

    return (
        <div className="homepage">     
        <div className="news-box">
            <img src="/image/02.png" alt=''></img>
            <div className="container"> 
               <div className="breadcrumb news-details">
                   <Link to='/'>首页</Link>
                   <RightOutlined />                   
                   <Link to={libkObj[type].link} >
                       {libkObj[type].name}
                   </Link>
                   <RightOutlined />
                   <span>详情-{`${detailsKey}`}</span>
               </div>  
               <div className="details-cnt">
                   标题
                   <Tiptap></Tiptap>                                                                                         
               </div>                                                                                                                                                               
            </div>              
        </div>                                                                                                                    
     </div>     
    );
};
export default (NewDetails);