import {
    READYTOREFRESH,
    READYTOREFRESH_SCENE,
    CELAER_DETAILS, 
    CELAER_DATAlIST, 
    CLEAR_VIEWDATA,
    OPEN_NODES_MODEL,
    OPEN_SHIP_MODEL,
    GET_NODES_INFO,
    OPEN_MAP,
    GET_SAVE_MODEL,
    ADD_SCENE,
    GET_PROJECT_LIST,
    GET_INPROJECT_SCENE_LIST,
    DELETE_SCENE,
    EDIT_SCENE,
    EDIT_SCENE_NAME,
    GET_FILE_DETAILS,
    EDIT_FILE,
    EDIT_FILE_BG,
    EDIT_SCENE_ISMODEL,
    EDIT_SCENE_SHARE,
    GET_SCENE_DETAILS, 
    OPEN_NODES_COVER, 
    // EDIT_SCENE_ASSET,    
    GET_SCENE_MODEL, 
    DELETE_SCENE_MODEL,
    SHOW_COMMENT,
    GET_COMMENT_LIST,
    ADD_COMMENT_LIST,
    DELETE_COMMENT,   
    // ORDER_POINT,    
    GET_OPEN_EDITOR, 
    GET_ARTICLE_LIST,
    ADD_ARTICLE,
    EDIT_ARTICLE,
    DELETE_ARTICLE,
    GET_ARTICLE_DETAILS,
    CLEAR_ARTICLE,
    GET_ADDARTICLE_STATE,
    GET_TAGlIST,  
    GET_NEWS_CONTENT,    
    GET_NODES_TABLE,
    ADD_NODES,
    EDIT_NODES,
    EDIT_NODES_CELL,
    DELETE_NODES,
    GET_SHIP_TABLE,
    ADD_SHIP,
    EDIT_SHIP,
    DELETE_SHIP,
    GET_SCH_NODE,
    GET_VIEW,
    GET_NODES_DETAILS,
    GET_SHIP_DETAILS,
    EDIT_NODES_CENTER,
    OPEN_DETAILS,
    OPEN_SHIPDETAILS,
    EDIT_FILTER,
    EDIT_VIEW_LAYOUT,
    PREVIE_VIEW_FILTER,
    IMPORT_SHIPS,
    GET_IMG_LIST,
    EDIT_VIEWDATA_CENTER,
  
} from '../actions/app';

// import util from '../services/Util'
const defaultState = {    
     openNodes:false,
     openShip:false,
     nodesInfo:null,
     openMap:false,
     openSceneModel:false,  
     sceneList:[],
     sceneTotal:0,
     sceneDetails:null,
     sceneAsset:[], 
     sceneAssetDetails:null,
     itemInfor:null,
     sceneModelList:[],
     sceneModelTotal:0,
     showComment:false,
     commentList:[],
     commentTotal:0,
     pointList:[],
     pointTotal:0,
     pointDetails:null,
     openEditor:false,
     articleList:[],
     articleDetails:null,
     isAddArticle:false,
     tagList:[],
     perfectList:[],
     tipTapContent:null,
     sceneAssetId:'', 
     schMember:[],
     fileDetails:null,
     projectList:[],  
     nodesTableList:[],
     nodesTableTotal:0,
     shipTable:[],
     sjipTotal:0,
     schNodesList:[], 
     schNodesTotal:0,
     viewData:null,
     nodesDetails:null,
     shipDetails:null,
     openDetails:null,
     openShipDetails:null,
     nodesTypeArr: [
        {'name':"人物","value":1},
        {'name':"事件","value":2},
        {'name':"地点","value":3},
        {'name':"作品","value":4},
        {'name':"成就","value":5},
        {'name':"文章","value":6},
        {'name':"家谱","value":7},
    ],
    bgImgList:[],
    bgImgTotal:0,
};

const home = (state = defaultState, action) => {
    switch (action.type) {
        //清除数据[展厅资源]
        case READYTOREFRESH:{
            return {
                ...state,
                sceneAsset:[],                                  
            }
        }
        //清除数据[展厅点位，详情]
        case READYTOREFRESH_SCENE:{
            return {
                ...state,
                sceneDetails:null,   
                pointList:[]                              
            }
        } 
        //清除详情        
        case CELAER_DETAILS:{
            return {
                ...state,
                nodesDetails:null,   
                shipDetails:null,
                // viewData:null,                                          
            }
        }
        //清除数组 
        case CELAER_DATAlIST:{
            return {
                ...state,              
                nodesTableList:[],
                shipTable:[]                           
            }
        }
        //清除视图
        case CLEAR_VIEWDATA:{
            return {
                ...state,   
                viewData:null                         
            }
        }
        //打开节点详情
        case OPEN_DETAILS:{
            return {
                ...state,
                openDetails:action.openDetails,  
            }
        } 
         //打开关系详情
         case OPEN_SHIPDETAILS:{
            return {
                ...state,
                openShipDetails:action.openShipDetails,  
            }
        }      
        //打开添加节点
        case OPEN_NODES_MODEL:{
            return {
                ...state,
                openNodes:action.openNodes,  
            }
        }  
         //打开添加关系
         case OPEN_SHIP_MODEL:{
            return {
                ...state,
                openShip:action.openShip,  
            }
        }
        //获取表单节点信息
        case GET_NODES_INFO:{
            return {
                ...state,
                nodesInfo:action.nodesInfo,  
            }
        } 
        //打开地图
        case OPEN_MAP:{
            return {
                ...state,
                openMap:action.openMap,  
            }
        } 
        //保存场景弹窗 
        case GET_SAVE_MODEL:{
            return{
                ...state,
                openSceneModel:action.openSceneModel
            }           
        } 
        //获取项目内列表   
        case GET_INPROJECT_SCENE_LIST: 
            if (!action.error) { 
                let sceneList =[];           
                if(action.page === 1){                                                    
                    sceneList = action.payload.data                 
                    }else{
                        sceneList = JSON.parse(JSON.stringify(state.sceneList));
                        sceneList = sceneList.concat(action.payload.data);
                    }
                    return {
                        ...state,
                        sceneList:sceneList,
                        sceneTotal:action.payload.total ,                                                                                       
                    };    
            }else{
                return state;
            };                 
        //新增展厅 
        case ADD_SCENE: 
         if (!action.error) {
            let sceneList = Object.assign([], state.sceneList); 
            sceneList.unshift(action.payload.data)
              return {
                  ...state,
                  sceneList: sceneList,                                        
              }
          }else{
              return state;
          }; 
        //删除展厅
        case DELETE_SCENE: 
            if (!action.error) { 
                let sceneList = Object.assign([], state.sceneList);
                let index = sceneList.findIndex((item)=>item._key === action.fileKey)  
                if(index !== -1){
                    sceneList.splice(index,1)
                }         
                return {
                    ...state,
                    sceneList:sceneList,                                        
                }
            }else{
                return state;
            };  
       
        //编辑展厅名字
        case EDIT_SCENE_NAME: 
            if (!action.error) { 
                let sceneList = Object.assign([], state.sceneList);
                let index = sceneList.findIndex((item)=>item._key === action.param.fileKey)  
                if(index !== -1){                
                    // sceneList[index].name = action.param.name
                    for(let key in action.param){                     
                        sceneList[index][key] = action.param[key] 
                    } 
                }         
                return {
                    ...state,
                    sceneList:sceneList,                                        
                }
            }else{
                return state;
            }; 
        //获取文件详情
        case GET_FILE_DETAILS: 
            if (!action.error) {            
                return {
                    ...state,
                    fileDetails: action.payload.data                                                         
                }
            }else{
                return state;
            };
        case EDIT_FILE: 
            if (!action.error) {  
                let fileDetails = Object.assign({}, state.fileDetails); 
                for(let key in action.param){                     
                    fileDetails[key] = action.param[key] 
                }           
                return {
                    ...state,
                    fileDetails:fileDetails                                                         
                }
            }else{
                return state;
            };
        //编辑背景
        case EDIT_FILE_BG: 
            if (!action.error) {  
                let fileDetails = Object.assign({}, state.fileDetails);
                fileDetails.background = action.param.background
                return {
                    ...state,
                    fileDetails:fileDetails                                                         
                }
            }else{
                return state;
            };
        //编辑展厅是否公开
        case EDIT_SCENE_ISMODEL: 
            if (!action.error) { 
                let sceneList = Object.assign([], state.sceneList);
                let index = sceneList.findIndex((item)=>item._key === action.param.sceneKey) 
               
                if(index !== -1){                
                    sceneList[index].isModel = action.param.isModel
                }         
                return {
                    ...state,
                    sceneList:sceneList,                                        
                }
            }else{
                return state;
            };
        //公开分享,允许编辑,需要登陆 
        case EDIT_SCENE_SHARE: 
            if (!action.error) { 
                let sceneList = Object.assign([], state.sceneList);
                let sceneDetails = Object.assign({}, state.sceneDetails);
                let index = sceneList.findIndex((item)=>item._key === action.param.sceneKey)
                // console.log('action.param',action.param);           
                if(index !== -1){                
                    sceneList[index].hasShared = action.param.hasShared
                    sceneList[index].allowEdit = action.param.allowEdit
                    sceneList[index].needLogin = action.param.needLogin
                } 
                for(let key in action.param){                     
                    sceneDetails[key] = action.param[key] 
                } 
                // console.log('action.param.sceneDetails---',sceneDetails);       
                return {
                    ...state,
                    sceneList:sceneList, 
                    sceneDetails:sceneDetails                                       
                }
            }else{
                return state;
            }; 
        //获取展厅详情
        case GET_SCENE_DETAILS: 
            if (!action.error) {            
                return {
                    ...state,
                    sceneDetails: action.payload.data                                                         
                }
            }else{
                return state;
            }; 
         //更新展厅配置信息
         case EDIT_SCENE: 
            if (!action.error) { 
                // console.log('更新展厅配置信息',action.param);
                let sceneDetails = Object.assign({}, state.sceneDetails);               
                for(let key in action.param.detailJson){                     
                    sceneDetails.detailJson[key] = action.param.detailJson[key] 
                }         
                return {
                    ...state,
                    sceneDetails:sceneDetails,                                        
                }
            }else{
                return state;
            }; 
        
        //获取展厅模板列表
        case GET_SCENE_MODEL: 
            if (!action.error) { 
                let sceneModelList =[];           
                if(action.page === 1){                                                    
                    sceneModelList = action.payload.data                 
                }else{
                    sceneModelList = JSON.parse(JSON.stringify(state.sceneModelList));
                    sceneModelList = sceneModelList.concat(action.payload.data);
                }
                return {
                    ...state,
                    sceneModelList:sceneModelList,
                    sceneModelTotal:action.payload.total,                                                                                       
                };
            }else{
                return state;
            }; 
        //移除模板
        case DELETE_SCENE_MODEL: 
            if (!action.error) { 
            let sceneModelList = Object.assign([], state.sceneModelList);
            let index = sceneModelList.findIndex((item)=>item._key === action.param.sceneKey)
            if(index !== -1){                
                sceneModelList[index].isModel = 0
                sceneModelList.splice(index,1)
            }         
            return {
                ...state,
                sceneModelList:sceneModelList,                                        
            }
            }else{
                return state;
            }; 
        //展示留言弹窗         
        case SHOW_COMMENT:           
            if (!action.error) {  
                return {
                    ...state,
                    showComment: action.showComment                                     
                };                            
            } else{             
                return state;
            }
                 
       
        //打开编辑弹窗
        case GET_OPEN_EDITOR: 
            return {
                ...state,
                openEditor:action.openEditor,                                        
            }
         //标签列表         
         case GET_TAGlIST:           
            if (!action.error) {  
                return {
                    ...state,
                    tagList: action.payload.data                                     
                };                            
            } else{             
                return state;
            } 
        
        //获取文章列表
        case GET_ARTICLE_LIST: 
            if (!action.error) {                 
                return {
                    ...state,
                    articleList:action.payload.data                                                                                                         
                };
            }else{
                return state;
            }; 
        //新增文章
        case ADD_ARTICLE: 
            if (!action.error) {  
                console.log('添加文章action.params--',action.param);
                console.log('添加文章action.params--',action);         
                let articleList = Object.assign([], state.articleList); 
                articleList.unshift(action.param)
                return {
                    ...state,
                    articleList: articleList,                                        
                }
            }else{
                return state;
            }; 
        //编辑文章
        case EDIT_ARTICLE: 
            if (!action.error) {          
                let articleList = Object.assign([], state.articleList);
                let index = articleList.findIndex((point)=>point._key === action.param.cardKey)
                if(index !== -1){
                    for(let key in action.param){
                        articleList[index][key] = action.param[key] 
                    }
                }
                return {
                    ...state,
                    articleList: articleList,                                        
                }
            }else{
                return state;
            };   
        //删除文章
        case DELETE_ARTICLE: 
            if (!action.error) {          
                let articleList = Object.assign([], state.articleList);
                let index = articleList.findIndex((point)=>point._key === action.cardKey)
                if(index !== -1){
                    articleList.splice(index,1);
                }
                return {
                    ...state,
                    articleList: articleList,                                        
                }
            }else{
                return state;
            };  
        //获取文章详情
        case GET_ARTICLE_DETAILS: 
            if (!action.error) {                  
                return {
                    ...state,
                    articleDetails:action.payload.data,                                        
                }
            }else{
                return state;
            };
        //清除文章
        case CLEAR_ARTICLE: 
            return {
                ...state,
                articleDetails:null,
                // articleList:[]                                        
            } 
        //清除文章
        case GET_ADDARTICLE_STATE: 
            return {
                ...state,
                isAddArticle:action.isAddArticle,                                                     
            }
        //获取富文本内容
        case GET_NEWS_CONTENT: 
            return {
                ...state,
                tipTapContent:action.tipTapContent,                                                     
            } 
       //获取项目列表
       case GET_PROJECT_LIST: 
            if (!action.error) {            
                return {
                    ...state,
                    projectList: action.payload.data,                                                                            
                }
            }else{
                return state;
            }; 
      //获取节点表单列表
      case GET_NODES_TABLE: 
            if (!action.error) {  
                let list = action.payload.data              
                // var flag = list.some(({ isCenter })=> isCenter) 
                // if(flag){  
                //     console.log('存在');
                // }else{ //不存在
                //   console.log('不存在');
                //   list.forEach((item)=>{
                //       item.isCenter = false
                //   }) 
                //   if(action.page === 1){
                //     list[0].isCenter = true
                //   }
                // }             
                return {
                    ...state,
                    nodesTableList:list,
                    nodesTableTotal:action.payload.total,                                                                                       
                };    
            }else{
                return state;
            }; 
        //新增节点 
        case ADD_NODES: 
            if (!action.error) {
                let nodesTableList = Object.assign([], state.nodesTableList); 
                nodesTableList.push(action.param)  
                // let viewData  = Object.assign({}, state.viewData);
                // viewData.nodes.push(action.param)   
                let viewData  = Object.assign({}, state.viewData); 
                let nodes = viewData && viewData.nodes ? viewData.nodes :[]
                nodes.push(action.param)               
                return {
                    ...state,
                    nodesTableList: nodesTableList,   
                    viewData:viewData                                     
                }
            }else{
                return state;
            }; 
        //编辑节点
        case EDIT_NODES: 
            if (!action.error) { 
                let viewData  = Object.assign({}, state.viewData);                         
                let nodesTableList = Object.assign([], state.nodesTableList);            
                let index = nodesTableList.findIndex((item)=>item._key === action.param.nodeKey)  
                if(index !== -1){                    
                    nodesTableList[index].name = action.param.name
                    nodesTableList[index].nodeType = action.param.nodeType  
                    nodesTableList[index].timeString = action.param.timeString
                    nodesTableList[index].timeAccuracy = action.param.timeAccuracy
                    nodesTableList[index].begTime = action.param.begTime 
                    nodesTableList[index].endTime = action.param.endTime 
                    nodesTableList[index].description = action.param.description 
                    nodesTableList[index].imageType = action.param.imageType
                    nodesTableList[index].imageUrl = action.param.imageUrl
                    nodesTableList[index].imageSize =  action.param.imageSize 
                    nodesTableList[index].address =  action.param.address
                    nodesTableList[index].lng =  action.param.lng 
                    nodesTableList[index].lat =  action.param.lat   
                } 
                if(viewData && viewData.nodes && viewData.nodes.length){
                    let nodes = viewData.nodes
                    let index2 = nodes.findIndex((item)=>item._key === action.param.nodeKey)  
                    nodes[index2].name = action.param.name
                    nodes[index2].nodeType = action.param.nodeType  
                    nodes[index2].timeString = action.param.timeString
                    nodes[index2].timeAccuracy = action.param.timeAccuracy
                    nodes[index2].begTime = action.param.begTime 
                    nodes[index2].endTime = action.param.endTime 
                    nodes[index2].description = action.param.description 
                    nodes[index2].imageType = action.param.imageType
                    nodes[index2].imageUrl = action.param.imageUrl
                    nodes[index2].imageSize =  action.param.imageSize
                    nodes[index2].address =  action.param.address
                    nodes[index2].lng =  action.param.lng 
                    nodes[index2].lat =  action.param.lat 
                }
                return {
                    ...state,
                    nodesTableList:nodesTableList, 
                    viewData:viewData                                       
                }
            }else{
                return state;
            }; 
        //编辑节点行内编辑table
        case EDIT_NODES_CELL: 
            if (!action.error) {   
                // console.log('action.param',action.param); 
                let nodesTableList = Object.assign([], state.nodesTableList);            
                let index = nodesTableList.findIndex((item)=>item._key === action.param.nodeKey)  
                if(index !== -1){                    
                    // nodesTableList[index].name = action.param.name
                    // nodesTableList[index].nodeType = action.param.nodeType  
                    // nodesTableList[index].timeString = action.param.timeString
                    // nodesTableList[index].timeAccuracy = action.param.timeAccuracy                   
                    // nodesTableList[index].description = action.param.description                                   
                    for(let key in action.param){                     
                        nodesTableList[index][key] = action.param[key] 
                    }  
                }  
                return {
                    ...state,
                    nodesTableList:nodesTableList,                                        
                }
            }else{
                return state;
            };
        //编辑节点图标 
        case OPEN_NODES_COVER: 
            if (!action.error) {                               
                let nodesTableList = Object.assign([], state.nodesTableList);            
                let index = nodesTableList.findIndex((item)=>item._key === action.param.nodeKey)  
                if(index !== -1){ 
                    if(action.param.imageType === 1){
                        nodesTableList[index].imageSize =  action.param.imageSize 
                    }
                    nodesTableList[index].imageType = action.param.imageType
                    nodesTableList[index].imageUrl = action.param.imageUrl
                }         
                return {
                    ...state,
                    nodesTableList:nodesTableList,                                        
                }
            }else{
                return state;
            };
         //设置节点中心点
         case EDIT_NODES_CENTER: 
            if (!action.error) {                               
                let list = Object.assign([], state.nodesTableList);                                     
                var flag = list.some(({ isCenter })=> isCenter) 
                // if(flag){  //存在  
                //     list.forEach((item)=>{
                //         item.isCenter = false
                //     })                 
                // }else{                                   
                // } 
                list.forEach((item)=>{
                    item.isCenter = false
                }) 
                console.log('list---',list);
                let index = list.findIndex((item)=>item._key === action.param.nodeKey) 
                console.log('action.param.isCenter---',action.param.isCenter);
                if(index !== -1){                    
                    list[index].isCenter = action.param.isCenter
                }     
                return {
                    ...state,
                    nodesTableList:list,                                        
                }
            }else{
                return state;
            };  
        //删除节点
        case DELETE_NODES: 
            if (!action.error) {
                let nodesTableList = Object.assign([], state.nodesTableList);
                let nodesTotal = state.nodesTotal
                let index = nodesTableList.findIndex((item)=>item._key === action.nodeKey)  
                if(index !== -1){                   
                    if(nodesTableList[index].isCenter){
                        nodesTableList[0].isCenter = true
                    }
                    nodesTableList.splice(index,1)
                }         
                return {
                    ...state,
                    nodesTableList:nodesTableList,
                    nodesTotal:nodesTotal - 1                                        
                }
            }else{
                return state;
            }; 
       //获取关系表单列表
       case GET_SHIP_TABLE: 
            if (!action.error) {            
                return {
                    ...state,
                    shipTable: action.payload.data,
                    shipTotal:action.payload.total                                                         
                }
            }else{
                return state;
            }; 
        //新增关系 
        case ADD_SHIP: 
            if (!action.error) {
                console.log('新增关系action.param--',action.param);
                let shipTable = Object.assign([], state.shipTable); 
                shipTable.push(action.param)   
                let viewData  = Object.assign({}, state.viewData); 
                let links = viewData && viewData.links ? viewData.links :[]
                links.push(action.param)  
                console.log('新增关系action.param-links-',viewData);               
                return {
                    ...state,
                    shipTable: shipTable,
                    viewData:viewData                                        
                }
            }else{
                return state;
            }; 
        //编辑关系
        case EDIT_SHIP: 
            if (!action.error) { 
                console.log('修改关系',action.param);
                let viewData  = Object.assign({}, state.viewData);                               
                let shipTable = Object.assign([], state.shipTable);            
                let index = shipTable.findIndex((item)=>item._key === action.param.relationKey)  
                if(index !== -1){   
                    for(let key in action.param){                     
                        shipTable[index][key] = action.param[key] 
                    }  
                } 
                if(viewData && viewData.links && viewData.links.length){                  
                    let links = viewData.links
                    let index2 = links.findIndex((item)=>item._key === action.param.relationKey)  
                    links[index2].fromNode = action.param.fromNode
                    links[index2].toNode = action.param.toNode
                    links[index2].relationType = action.param.relationType  
                    links[index2].relationDesc = action.param.relationDesc
                    links[index2].timeString = action.param.timeString
                    links[index2].timeAccuracy = action.param.timeAccuracy
                    links[index2].begTime = action.param.begTime 
                    links[index2].endTime = action.param.endTime 
                    links[index2].description = action.param.description
                    links[index2].address = action.param.address                  
                    links[index2].lng =  action.param.lng 
                    links[index2].lat =  action.param.lat 
                    links[index2].relationMemo =  action.param.relationMemo 
                }  
                console.log('编辑关系viewData.links',viewData)      
                return {
                    ...state,
                    shipTable:shipTable,   
                    viewData:viewData                                     
                }
            }else{
                return state;
            };   
        //删除关系
        case DELETE_SHIP: 
            if (!action.error) { 
                let shipTable = Object.assign([], state.shipTable);
                let shipTotal = state.shipTotal
                let index = shipTable.findIndex((item)=>item._key === action.relationKey)  
                if(index !== -1){
                    shipTable.splice(index,1)
                }         
                return {
                    ...state,
                    shipTable:shipTable,
                    shipTotal:shipTotal - 1                                        
                }
            }else{
                return state;
            };
          //搜索节点  
          case GET_SCH_NODE:  
            if (!action.error) { 
                let schNodesList =[];           
                if(action.page === 1){                                                    
                    schNodesList = action.payload.data                 
                    }else{
                        schNodesList = JSON.parse(JSON.stringify(state.schNodesList));
                        schNodesList = schNodesList.concat(action.payload.data);
                    }
                    return {
                        ...state,
                        schNodesList:schNodesList,
                        schNodesTotal:action.payload.total,                                                                                       
                    };    
            }else{
                return state;
            }; 
       //获取视图列表
       case GET_VIEW: 
            if (!action.error) {   
                const nodeTypeArr = [1,2,3,4,5,6] //节点类型              
                let viewData  = action.payload.data;       
                let nodes = viewData.nodes
                let filters = viewData.defaultFilter ? viewData.defaultFilter:nodeTypeArr
                // console.log('filters---',filters); 
                // nodes.forEach((item)=>{
                //     item.show = false
                // })

                //判断数据是否设置过中心点
                //判断是否设置过滤数组
                //如果没设置过，默认显示全部节点
                //如果设置过，但是全部取消勾选，默认显示中心节点        
                                                    
                var flag = nodes.some(({ isCenter })=> isCenter) 
                if(flag){ //存在  
                    nodes.forEach((item)=>{  
                        item.show = false                    
                    })                 
                }else{ //不存在   
                     nodes[0].isCenter = true                             
                }
                if(filters.length){                   
                    for(var i = 0 ; i< filters.length ; i++){
                        for(var j = 0;j < nodes.length ; j ++){     
                            if(nodes[j].nodeType == filters[i]){
                                nodes[j].show = true
                            }
                        }
                    }
                }else{
                    nodes.forEach((item)=>{                                         
                        if(item.isCenter){ //中心点                           
                            item.show = true
                        }
                    })  
                }  
                console.log('viewData--',viewData);             
                return {
                    ...state,
                    viewData: viewData                                                                        
                }
            }else{
                return state;
            };
        //节点详情
       case GET_NODES_DETAILS: 
            if (!action.error) {            
                return {
                    ...state,
                    nodesDetails: action.payload.data,                                                                         
                }
            }else{
                return state;
            };
        //关系详情
       case GET_SHIP_DETAILS: 
            if (!action.error) {            
                return {
                    ...state,
                    shipDetails: action.payload.data,                                                                         
                }
            }else{
                return state;
            };
        //保存默认过滤项目 
        case EDIT_FILTER: 
            if (!action.error) { 
                console.log('过滤条件',action.filterArr);                 
                let viewData  = Object.assign({}, state.viewData); 
                console.log('过滤条件viewData',viewData);  
                viewData.defaultFilter = action.filterArr               
                let filters = action.filterArr  
                let nodes = viewData.nodes  
                nodes.forEach((item)=>{
                    item.show = false                   
                })
                if(filters.length){
                    for(var i = 0 ; i< filters.length ; i++){
                        for(var j = 0;j < nodes.length ; j ++){     
                            if(nodes[j].nodeType == filters[i] || nodes[j].isCenter){
                                nodes[j].show = true
                            }
                        }
                    }
                }else{
                    for(var g = 0;g < nodes.length ; g ++){ 
                        if(nodes[g].isCenter){ //中心点
                            console.log('111111');
                            nodes[g].show = true
                        }
                    } 
                }                  
                console.log('过滤 viewData',viewData);              
                return {
                    ...state,
                    viewData: viewData,                                                                         
                }
            }else{
                return state;
            };
        //预览视图条件筛选 
        case PREVIE_VIEW_FILTER: 
            if (!action.error) { 
                console.log('过滤条件',action.filterArr); 
                console.log('centerId--',action.centerId);                
                let viewData  = Object.assign({}, state.viewData);                 
                viewData.defaultFilter = action.filterArr               
                let filters = action.filterArr  
                let nodes = viewData.nodes  
                nodes.forEach((item)=>{
                    item.show = false                   
                })
                if(filters.length){
                    for(var i = 0 ; i< filters.length ; i++){
                        for(var j = 0;j < nodes.length ; j ++){     
                            if(nodes[j].nodeType == filters[i] || nodes[j].isCenter){
                                nodes[j].show = true
                            }
                            if(nodes[j]._key === action.centerId){
                                nodes[j].show = true
                            }
                        }
                    }
                }else{
                    for(var g = 0;g < nodes.length ; g ++){ 
                        if(nodes[g].isCenter){ //中心点                         
                            nodes[g].show = true
                        }                        
                        if(nodes[g]._key === action.centerId){
                            nodes[g].show = true
                        }
                    } 
                }                  
                console.log('预览过滤 viewData',viewData);              
                return {
                    ...state,
                    viewData: viewData,                                                                         
                }
            }else{
                return state;
            };
        
        //保存视图模式
        case EDIT_VIEW_LAYOUT: 
            if (!action.error) { 
                console.log('过滤模式',action.viewType);                 
                let viewData  = Object.assign({}, state.viewData); 
                // viewData.viewType = action.viewType
                // console.log('过滤 viewData',viewData);              
                return {
                    ...state,
                    viewData: viewData,                                                                         
                }
            }else{
                return state;
            };
        //批量导入关系 
        case IMPORT_SHIPS: 
            if (!action.error) { 
                let shipTable = Object.assign([], state.shipTable); 
                shipTable.push(action.param)                           
                return {
                    ...state,
                    shipTable: shipTable,                                                                         
                }
            }else{
                return state;
            };
        //图库
        case GET_IMG_LIST:           
            if (!action.error) { 
                let bgImgList =[];           
                if(action.page === 1){                                    
                    bgImgList = action.payload.data                 
                }else{
                    bgImgList = JSON.parse(JSON.stringify(state.bgImgList));
                    bgImgList = bgImgList.concat(action.payload.data);
                }               
                return {
                    ...state,
                    bgImgList: bgImgList,
                    bgImgTotal: action.payload.total,                    
                };
                            
            } else{             
                return state;
            } 
        //修改视图中心点 
        case EDIT_VIEWDATA_CENTER: 
            if (!action.error) {  
                let viewData  = Object.assign({}, state.viewData);
                let nodes = viewData.nodes                              
                nodes.forEach((item)=>{
                    item.isCenter = false
                })
                let index = nodes.findIndex((item)=>item._key === action.nodeKey) 
                if(index !== -1){                    
                    nodes[index].isCenter = true
                }  
                console.log('修改视图中心点 ',viewData);   
                return {
                    ...state,
                    viewData:viewData,                                        
                }               
            }else{
                return state;
            };      
        default:
            return state;
    }
};

export default home;
