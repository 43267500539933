import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Tabs } from "antd";
import "./buy.css";
import {} from "../../actions/app";
// import util from "../../services/Util";
import BuyItem from './butItem'

const Buy = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  //   let token = util.common.getQueryString("token") || "";
  const auth = useSelector((state) => state.auth);
  const user = auth.user;
  const [tabKey, setTabKey] = useState("11");
  const [timeKey, setTimeKey] = useState(0);
  const [typekey, setTypeKey] = useState('all');
  const [sortsKey, setSortsKey] = useState('all');

  const tabArr = [
    { lable: "采购意向", key: "11" },
    { lable: "采购公告", key: "12" },
    { lable: "变更公告", key: "13" },
    { lable: "结果公布", key: "14" },
  ];

  const timeArr = [
    {"name":"当天","key":0},
    {"name":"第三天","key":1},
    {"name":"近十天","key":2},
    {"name":"近一月","key":3},
    {"name":"近三月","key":4},
    {"name":"时间区间","key":5}
  ]
  const typeArr = [
    {"name":"不限","key":"all"},
    {"name":"工程结束","key":"1"},
    {"name":"政府采购","key":"2"},
    {"name":"土地使用权","key":"3"},
    {"name":"矿业权","key":"4"},
    {"name":"国有产权","key":"5"},
    {"name":"碳排放权","key":"6"},
    {"name":"排污权","key":"7"},
    {"name":"林权","key":"8"},
    {"name":"其他","key":"9"},
  ]

  const sortsArr = [
    {"name":"不限","key":"all"},
    {"name":"农业","key":"1"},
    {"name":"林业","key":"2"},
    {"name":"畜牧业","key":"3"},
    {"name":"渔业","key":"4"},   
  ]

  //切换tab
  const onChange = (key) => {
    setTabKey(key);
  };

  return (
    <div className="homepage">
      <div className="goverment-box">
        <img src="/image/02.png" alt=""></img>
        <div className="container">
          <div className="works-cnt">
            <div className="buy-cnt">
              <Tabs
                onChange={onChange}
                type="card"
                activeKey={tabKey}
                items={tabArr.map((item, index) => {
                  return {
                    label: `${item.lable}`,
                    key: `${item.key}`,
                    children: (
                      <div className="buy-tab-cnt">
                        <div className="buy-tab-top">
                          <div className="buy-time buy-item">
                              <div className="buy-top-title">发布时间：</div>
                               <div className="buy-top-right">
                              {
                                timeArr.map((time,index)=>{
                                  return(
                                  <div key={'time'+index} 
                                  className={"change-item " + (timeKey === time.key ? 'active':'') }   
                                  onClick={()=>{setTimeKey(time.key)}}                                
                                  >{time.name}</div>
                                  )
                                })
                              } 
                              </div>
                          </div>
                          <div className="buy-type buy-item">
                            <div className="buy-top-title">业务类型：</div>
                            <div className="buy-top-right">
                            {
                                typeArr.map((type,index)=>{
                                  return(
                                  <div key={'type'+index}                                   
                                    className={"change-item " + (typekey === type.key ? 'active':'') }
                                    onClick={()=>{setTypeKey(type.key)}} 
                                  >{type.name}</div>
                                  )
                                })
                              } 
                          </div>
                          </div>
                          <div className="buy-sorts buy-item">
                            <div className="buy-top-title">行业：</div>
                            <div className="buy-top-right">
                            {
                                sortsArr.map((sorts,index)=>{
                                  return(
                                  <div key={'sorts'+index}                                   
                                  className={"change-item " + (sortsKey === sorts.key ? 'active':'') }
                                  onClick={()=>{setSortsKey(sorts.key)}} 
                                  >{sorts.name}</div>
                                  )
                                })
                              }
                          </div>
                          </div>
                        </div>
                        {
                          <BuyItem></BuyItem>
                        }
                      </div>
                    ),
                  };
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Buy;
