import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Segmented } from "antd";
import "./works.css";
import {} from "../../actions/app";
// import util from "../../services/Util";
import WorksPanel from './worksPanel'

const Works = () => {
  const dispatch = useDispatch();
  const history = useHistory();
//   let token = util.common.getQueryString("token") || "";
  const auth = useSelector((state) => state.auth);
  const user = auth.user;
  const [tabKey, setTabKey] = useState("new");
  const tabArr = [
    {
      label: (
        <div
          style={{
            padding: 4,
          }}
        >
          <div>最新</div>
        </div>
      ),
      value: "new",
    },
    {
      label: (
        <div
          style={{
            padding: 4,
          }}
        >
          <div>急聘</div>
        </div>
      ),
      value: "old",
    },
  ];

  return (
    <div className="homepage">
      <div className="goverment-box">
        <img src="/image/02.png" alt=""></img>
        <div className="container">
          <div className="works-cnt">
            <div className="works-tab">
              <Segmented
                options={tabArr}
                value={tabKey}
                onChange={setTabKey}
                block
              />
            </div>
            <div className="works-panel">
                {
                    tabKey === 'new' ?
                    <div><WorksPanel type="new"></WorksPanel></div>:null
                }
                {
                    tabKey === 'old' ?
                    <div><WorksPanel type="old"></WorksPanel></div>:null
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Works;
