import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, Card } from "antd";
import "./home.css";
import {} from "../../actions/app";
import util from "../../services/Util";
import api from "../../services/Api";
import moment from "moment";
import "moment/locale/zh-cn";

const { Meta } = Card;

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let token = util.common.getQueryString("token") || "";
  const auth = useSelector((state) => state.auth);
  const user = auth.user;
  // const home = useSelector((state) => state.home);
  // const developerList = home.developerList
  const [newsKey, setNewsKey] = useState(0);

  const tagArr = [
    { name: "两山农业", img: "" },
    { name: "两山林业", img: "" },
    { name: "两山水利", img: "" },
    { name: "两山文旅", img: "" },
    { name: "两山交通", img: "" },
    { name: "万安数字经济", img: "" },
  ];

  const newsList = [
    { title: "新闻1", img: "/image/carousel-01.png", info: "小标题1" },
    {
      title: "新闻2",
      img: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      info: "小标题2",
    },
    { title: "新闻3", img: "", info: "" },
    { title: "新闻4", img: "", info: "小标题4" },
    { title: "新闻5", img: "", info: "小标题5" },
    { title: "新闻6", img: "/image/carousel-01.png", info: "小标题6" },
  ];

  //宝库登录回调的token参数
//   useEffect(() => {
//     if (token) {
//       // console.log('登录返回的token');
//       window.localStorage.setItem("token", token);
//       getUserRole(token);
//     }
//   }, [dispatch, token]);

  //获取用户信息
//   const getUserRole = async () => {
//     //转存token
//     let res = await api.auth.getSwitchToken(token, 26, 20);
//     console.log("转存res---", res);
//     if (res.statusCode === "200") {
//       let switch_token = res.result.token;
//       window.localStorage.setItem("switch_token", switch_token);
//       dispatch(getUserInfoByToken(token));
//     }
//   };

  //退出登录
//   const loginOut = () => {
//     window.localStorage.clear();
//     dispatch(logout());
//     history.push("/");
//   };



  //监听窗口可视高度
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [dispatch]);

  return (
    <div className="home-page" onClick={()=>{history.push('/list')}}>
       <img src="/image/home-bg.jpg"></img>
    </div>
  );
};
export default Home;
