import axios from 'axios';
const API = 'https://baokudata.qingtime.cn/sgbh'; //宝库
const APIURL = 'https://kintime.qingtime.cn/sgbh';//当归
const yun3DData = 'https://3dfoxx.qingtime.cn' //云展厅
const relationData = 'https://rmapfoxx.qingtime.cn' //关系图谱
const soarData = 'https://soarfoxx.qingtime.cn' //soar数据
const shanData = 'https://tmfoxx.qingtime.cn'

let token =  window.localStorage.getItem('token') || '';

const requests = {
    get(path, params) {
      return new Promise(async function (resolve, reject) {       
        try {
          const response = await axios({
            method: 'get',
            url: path,
            params: params,  
            headers: {
              "Content-Type": "application/json",
              token: (window.localStorage.getItem('token')
              ? window.localStorage.getItem('token')
              : '')
            },        
          });
          resolve(response.data);         
        } catch (error) {
          reject(error);
          // console.log(error)                 
        }
      });
    },
    post(path, params) {
      return new Promise(async function (resolve, reject) {
        try {
          const response = await axios({
            method: 'post',
            url: path,
            data: params,
            headers: {
              "Content-Type": "application/json",
              token: (window.localStorage.getItem('token')
              ? window.localStorage.getItem('token')
              : '')
            },           
          });
          resolve(response.data);
        } catch (error) {
          reject(error);
        }
      });
    },
    put(path, params) {
      return new Promise(async function (resolve, reject) {
        try {
          const response = await axios({
            method: 'put',
            url: path,
            data: params,
            params: params,
            headers: {
              "Content-Type": "application/json",
              token: (window.localStorage.getItem('token')
              ? window.localStorage.getItem('token')
              : '')
            },           
          });
          resolve(response.data);
        } catch (error) {
          reject(error);
        }
      });
    },
    patch(path, params) {
      return new Promise(async function (resolve, reject) {
        try {
          const response = await axios({
            method: 'patch',
            url: path,
            data: params, 
            headers: {
              "Content-Type": "application/json",
              token: (window.localStorage.getItem('token')
              ? window.localStorage.getItem('token')
              : '')
            },          
          });
          resolve(response.data);
        } catch (error) {
          reject(error);
        }
      });
    },
    // _delete(path, params) {
    //   return new Promise(async function (resolve, reject) {       
    //     try {
    //       const response = await axios({
    //         method: 'delete',
    //         url: path,
    //         params: params,           
    //       });
    //       resolve(response.data);         
    //     } catch (error) {
    //       reject(error);
    //       // console.log(error)                 
    //     }
    //   });
    // },

    _delete(path, params) {
      return new Promise(async function (resolve, reject) {
        try {
          const response = await axios({
            method: 'delete',
            url: path,
            data: params,
            headers: {
              "Content-Type": "application/json",
              token: (window.localStorage.getItem('token')
              ? window.localStorage.getItem('token')
              : '')
            },           
          });
          resolve(response.data);
        } catch (error) {
          reject(error);
        }
      });
    },    
};

const auth = {
    // 获取七牛云uptoken
    getUptoken(token) {
        return requests.get(API + '/upTokenQiniu/getQiNiuUpToken', {
            token: token,
            bucketType: 19
        });
    },
 
    //转存token-宝库
    getSwitchToken(token,appHigh,app) {
        return requests.get(API + '/account/switchToken',{
            'token':token,
            appHigh:appHigh, 
            app:app,        
        });
    },      
    //保存用户信息
    getSaveUserInfor(userKey, userName, userAvatar, email, mobile,token,appHigh,app){
        return requests.patch(yun3DData + '/user/info',{           
            userKey:userKey, 
            userName:userName, 
            userAvatar:userAvatar, 
            email:email, 
            mobile:mobile,
            token:token,
            appHigh:appHigh,
            app:app,
        });      
    },
     
    //登录
    login(mobileArea,mobile,password,ip,appHigh){
      return requests.post(shanData + '/account/loginByPassword', {
        mobileArea,mobile,password,ip,appHigh
     });
    },
    //根据token获取用户信息
    getUserInfoByToken(){
      return requests.get(shanData + '/user');
    },  
    //注册
    register(mobileArea,mobile,password,code,email){
      return requests.post(shanData + '/account', {
        mobileArea,mobile,password,code,email
     });
    },
    //重置密码
    resetPwd(mobileArea, mobile, code, password){
      return requests.patch(shanData + '/account/pwdSet', {
        mobileArea, mobile, code, password
     });
    },
    //发送验证码 source(1:注册，2:验证码登陆,3:忘记密码)
    getCode(mobileArea, mobile, source){
      return requests.post(shanData + '/account/vertifyCode', {
        mobileArea, mobile, source
     });
    },
    //检验验证码
    checkCode(mobileArea, mobile, code){
      return requests.post(shanData + '/account/code/check', {
        mobileArea, mobile, code
     });
    },
   
    //修改用户信息 token,userAvatar,userName
    // editUserInfo(param){
    //     return requests.patch(qqData + '/user/info',param);
    // }
     
}
const common = {
  //获取七牛文件列表
  getQinNiuList(token, limit,page) {
    return requests.get(yun3DData + '/qiniu', {
      token: token,
      limit: limit,
      page: page,
    });
  },

  //记录七牛文件 token, cardKey(卡片key,在卡片里上传时必传，否则不需要), url(七牛链接), fileSize(文件大小)
  postQinNiu(token, cardKey,url,fileSize) {
    return requests.get(relationData + '/qiniu', {
      token: token,
      cardKey: cardKey,
      url: url,
      fileSize:fileSize
    });
  },
  //删除七牛文件 token, qiniuKey(七牛文件key)
  deleteQinNiu(token, qiniuKey) {
    return requests._delete(relationData + '/qiniu', {
      token: token,
      qiniuKey: qiniuKey,     
    });
  },
  //剩余空间
  getRemain(token, fileSize) {
    return requests.get(relationData + '/qiniu/remain', {
      token: token,
      fileSize: fileSize,     
    });
  },
  //记录上传七牛文件【卡片key,在卡片里上传时必传，否则不需要】 
  postQinNiu(token,cardKey,url,fileSize){
    return requests.post(relationData + '/qiniu', {
      token: token,
      cardKey: cardKey,  
      url:url,
      fileSize:fileSize  
    });
  }
}
//home
const home = {
  //图库web端 
  getBgImgList(page,limit){
      return requests.get('https://timeosdata.qingtime.cn/wallPaper',{ 
          tagKey:"2102224858", 
          page:page,
          limit:limit               
      });
   }, 
    //新建项目
    addProject(token,name,cover){
      return requests.post(relationData + '/project',{  
        token:token,
        name:name,
        cover:cover,                 
      })
    },
    //编辑项目
    editProject(token,projectKey,name,cover){
      return requests.patch(relationData + '/project',{ 
        token:token, 
        projectKey:projectKey,
        name:name,
        cover:cover,                 
      })
    },
    //删除项目
    deleteProject(token,projectKey) {
      return requests._delete(relationData + '/project', {
        token:token,
        projectKey: projectKey,         
      });
    },
    //我的项目
    getProject(name) {
      return requests.get(relationData + '/project', {
        // token:token,
        name: name,         
      });
    },

    //获取项目内展厅列表
    getInProjectSceneList(projectKey,page,limit){
      return requests.get(relationData + '/file',{
        projectKey:projectKey,      
        page:page,
        limit:limit
      }) 
    },

    //新建展厅 token,name,background,description   
    addScene(param){
      return requests.post(relationData + '/file',param) 
    },
    //编辑展厅名字
    editSceneName(fileKey,name,background,description){
      return requests.patch(relationData + '/file',{       
        fileKey:fileKey,
        name:name,
        background:background,
        description:description
      }) 
    },    
    //删除展厅
    deleteScene(token,fileKey){
      return requests._delete( relationData + '/file',{
        token:token,
        fileKey:fileKey
      });
    }, 
    //获取文件详情
    getFileDetails(token,fileKey){      
      return requests.get(relationData + '/file/detail',{
        token:token,
        fileKey:fileKey,        
      })         
    }, 
    //编辑文件 fileKey,name,background,description
    editFileDetails(param){
      return requests.patch(relationData + '/file',param) 
    },   
    //设置展厅是否公开
    editSceneIsModel(token,sceneKey,isModel,projectKey){
      return requests.patch(relationData + '/scene',{
        token:token,
        sceneKey:sceneKey,
        isModel:isModel,
        projectKey:projectKey
      }) 
    },

    //公开分享,允许编辑,需要登陆
    editSceneShare(token,sceneKey,hasShared,allowEdit,needLogin,projectKey){
      return requests.patch(relationData + '/scene',{
        token:token,
        sceneKey:sceneKey,
        hasShared:hasShared,
        allowEdit:allowEdit,
        needLogin:needLogin,
        projectKey:projectKey
      }) 
    },
    
    //编辑展厅 
    //token:string,sceneKey:string(展厅key),detailJson:json(展厅内容),
    //name:string(展厅名),cover:string(封面url),description:string(展厅描述), isModel:1/0(是否公开)}
    editScene(param){
      return requests.patch(yun3DData + '/scene',param) 
    },    
    //获取展厅详情
    getSceneDetails(token,sceneKey,projectKey){      
      return requests.get(yun3DData + '/scene/detail',{
        token:token,
        sceneKey:sceneKey,
        projectKey:projectKey
      })         
    }, 
    //获取展厅模板   
    getSceneModel(token,page,limit,projectKey){      
      return requests.get(yun3DData + '/scene/template',{
        token:token,
        page:page,
        limit:limit,
        projectKey:projectKey
      })         
    },
    //拷贝展厅  
    copySceneModel(token,sceneKey,projectKey){      
      return requests.post(yun3DData + '/scene/copy',{
        token:token,
        sceneKey:sceneKey,
        projectKey:projectKey      
      })         
    },    
    //文章列表
    getArticleList(sceneKey,projectKey){
      return requests.get(yun3DData + '/article',{
        sceneKey:sceneKey, 
        projectKey:projectKey          
      }) 
    },
    //新增文章
    addArticle(token,sceneKey,title,content,summary,cover,imageList){
      return requests.post(yun3DData + '/article',{
        token:token,
        sceneKey:sceneKey, 
        title:title,
        content:content,
        summary:summary,
        cover:cover,
        imageList:imageList         
      }) 
    },
    //编辑文章
    editArticle(token,cardKey,title,content,summary,cover,imageList){
      return requests.patch(yun3DData + '/article',{
        token:token,
        cardKey:cardKey, 
        title:title,
        content:content,
        summary:summary,
        cover:cover ,
        imageList:imageList        
      }) 
    },
    //删除文章
    deleteArticle(token,cardKey,projectKey){
      return requests._delete(yun3DData + '/article',{
        token:token,
        cardKey:cardKey, 
        projectKey:projectKey                
      }) 
    },
    //文章详情
    getArticledetails(cardKey,projectKey){
      return requests.get(yun3DData + '/article/detail',{     
        cardKey:cardKey, 
        projectKey:projectKey                
      })
    },
    //获取标签    
    getTagList(isFront){
      return requests.get(yun3DData + '/tag',{     
        isFront:isFront           
      })
    },
       
    //用户检索
    getSchMember(token,keyWord){
      return requests.get(relationData + '/user/search',{ 
        token:token, 
        keyWord:keyWord,                  
      })
    },
    //历史协作者
    getHistorySharer(token,limit){
      return requests.get(relationData + '/user/history',{ 
        token:token, 
        limit:limit,                  
      })
    },
    //定向分享项目[新增成员]
    addShareProject(token,projectKey,memberKey,role){
      return requests.post(relationData + '/member',{ 
        token:token, 
        projectKey:projectKey, 
        memberKey:memberKey,
        role:role                 
      })
    },
    //协作者列表
    getSharerList(projectKey) {
      return requests.get(relationData + '/member', {        
        projectKey: projectKey,              
      });
    },
    //修改协作者权限[0:所有者、1管理员、2编辑、3作者、4只读]
    editSharerRole(token,projectKey,memberKey,newRole) {
      return requests.patch(relationData + '/member', {  
        token:token,    
        projectKey: projectKey,
        memberKey:memberKey,
        newRole:newRole,         
      });
    },
    //删除协作者
    deleteSharer(token,projectKey,memberKey) {
      return requests._delete(relationData + '/member', {  
        token:token,    
        projectKey: projectKey,
        memberKey:memberKey         
      });
    },  
    //退出协作  
    exitShare(token,projectKey) {
      return requests._delete(relationData + '/member/exit', {   
        token:token,   
        projectKey: projectKey              
      });
    },   
    
    //获取节点表单列表 token fileKey name page limit
    getNodesTable(token,fileKey,name,page,limit) {
      return requests.get(relationData + '/node', {        
        token:token,
        fileKey:fileKey,
        name:name,
        page:page,
        limit:limit          
      });
    },
    //新增节点 
    //token,fileKey(文件key), name(节点名),nodeType(节点类型number:1-6), 
    //imageUrl(媒体url), imageType(媒体类型：1本地上传2引用), imageSize(媒体size)  
    //timeString(时间描述string),begTime(开始时间戳), endTime(结束时间戳), 
    //description(描述), lo(经度, la(纬度)
    addNodes(param){
      return requests.post(relationData + '/node',param) 
    },
    //编辑节点
    //token,nodeKey, name,nodeType, imageUrl, imageType, 
    //imageSize, description,timeString, begTime, endTime, lo, la
    editNodes(param){
      return requests.patch(relationData + '/node',param) 
    },    
    //删除节点
    deleteNodes(token,nodeKey){
      return requests._delete( relationData + '/node',{
        token:token,
        nodeKey:nodeKey
      });
    }, 
   
    //获取关系表单列表 token fileKey name page limit
    getShipTable(token,fileKey,name,page,limit) {
      return requests.get(relationData + '/relation', {        
        token:token,
        fileKey:fileKey,
        name:name,
        page:page,
        limit:limit          
      });
    },
    //新增关系  
    addShip(param){
      return requests.post(relationData + '/relation',param) 
    },
    //编辑关系   
    editShip(param){
      return requests.patch(relationData + '/relation',param) 
    },      
    //删除关系
    deleteShip(token,relationKey){
      return requests._delete( relationData + '/relation',{
        token:token,
        relationKey:relationKey
      });
    },
    //视图
    getViewData(token,fileKey){
      return requests.get(relationData + '/graph',{
        token:token,
        fileKey:fileKey
      });
    },
     //节点详情
     getNodesDetails(nodeKey){
      return requests.get(relationData + '/node/detail',{        
        nodeKey:nodeKey
      });
    },
     //关系详情
    getShipDetails(relationKey){
      return requests.get(relationData + '/relation/detail',{
        relationKey:relationKey
      });
    },
    //批量导入节点
    importNodes(token, fileKey, filePath){
      return requests.post(relationData + '/node/import',{
        token:token,
        fileKey:fileKey,
        filePath:filePath      
      });
    },

    //批量导入关系
    importShip(token, fileKey, filePath){
      return requests.post(relationData + '/relation/import',{
        token:token,
        fileKey:fileKey,
        filePath:filePath
      });
    },
    //高德地区搜索
    getSchGeoPlace(key,keywords,offset){
      return requests.get('http://restapi.amap.com/v3/place/text',{
        key:key,
        keywords:keywords,
        offset:offset
      });
    },
   //保存默认过滤项目    
   editFilter(token, fileKey, filterArr){
    return requests.patch(relationData + '/graph/defaultFilter',{
      token:token,
      fileKey:fileKey,
      filterArr:filterArr
    });
   },
   //保存视图模式    
   editViewLayout(token, fileKey, viewType){
    return requests.patch(relationData + '/graph/defaultFilter',{
      token:token,
      fileKey:fileKey,
      viewType:viewType
    });
   },
   //编辑节点位置
   editNodesPosition(token,nodeKey,x,y){
    return requests.patch(relationData + '/graph/position',{
      token:token,
      nodeKey:nodeKey,
      x:x,
      y:y
    });
   },
   //节点导出
   exportNodes(token,fileKey){
    return requests.get(relationData + '/node/export',{
      token:token,
      fileKey:fileKey,   
    });
   },
   //关系导出
   exportShips(token,fileKey){
    return requests.get(relationData + '/relation/export',{
      token:token,
      fileKey:fileKey,   
    });
   },
   
}

const soar = {
  testApi(content, pageUrl, url, apiUrl, apiParam,clientIp,contentType,userId,status){
    return requests.post('https://dhapi.library.sh.cn/rabbitmq_provider/mq/api/send/actionlog/insert',{     
      content, pageUrl, url, apiUrl, apiParam,clientIp,contentType,userId,status
    });
  },
  //获取七牛token
  getUptoken() {
    return requests.get(soarData + '/account/qiniuToken', {         
    });
  },
  //获取用户信息
  getUserInfo() {
    return requests.get(soarData + '/user', {         
    });
  },  
   //获取成员列表 
   getReportMember(teamKey,keyWord){
    return requests.get(soarData + '/reportConfig/member',{     
      teamKey:teamKey,
      keyWord:keyWord   
    });
   },
   //修改成员
   //teamKey,memberKey, showDay, showWeek, showMonth, showQuarter, showYear, showTotal, reportAdmin
   editMember(param){
    return requests.patch(soarData + '/reportConfig/member',param);
   },
   //获取成员配置
   getMemberConfig(teamKey){
    return requests.get(soarData + '/reportConfig/member/single',{     
      teamKey:teamKey      
    });
   },   
   //获取路线列表 
   getReviewLine(teamKey){
    return requests.get(soarData + '/reviewLine',{     
      teamKey:teamKey,      
    });
   },
   //新增路线列表 
   //teamKey, memberKeyArr, allowCopy, reviewUsers, copyUsers
  addReviewLine(teamKey, memberKeyArr, allowCopy, reviewUsers, copyUsers){
    return requests.post(soarData + '/reviewLine',{     
      teamKey:teamKey,  
      memberKeyArr:memberKeyArr,
      allowCopy:allowCopy,
      reviewUsers:reviewUsers,
      copyUsers:copyUsers 
    });
  },
  //编辑路线列表 
  //teamKey, lineKey, allowCopy, reviewUsers, copyUsers
  editReviewLine(teamKey, lineKey, allowCopy, reviewUsers, copyUsers){
    return requests.patch(soarData + '/reviewLine',{     
      teamKey:teamKey,  
      lineKey:lineKey,
      allowCopy:allowCopy,
      reviewUsers:reviewUsers,
      copyUsers:copyUsers   
    });
  },  
  //删除路线列表 
  deleteReviewLine(teamKey,lineKey){
    return requests._delete(soarData + '/reviewLine',{     
      teamKey:teamKey, 
      lineKey:lineKey     
    });
  },
  //是否允许抄送
  //teamKey, lineKey, allowCopy, reviewUsers, copyUsers
  editLineAllowCopy(teamKey, lineKey, allowCopy){
    return requests.patch(soarData + '/reviewLine',{     
      teamKey:teamKey,  
      lineKey:lineKey,
      allowCopy:allowCopy,       
    });
  },
  //获取路线图表
  getLineChart(teamKey){
    return requests.get(soarData + '/reviewLine/graph',{     
      teamKey:teamKey      
    });
   }, 
  //获取要素列表
  getFactorList(teamKey){
    return requests.get(soarData + '/reportConfig/field',{     
      teamKey:teamKey,      
    });
   },
   //新增要素
  addFactor(teamKey, fieldName, fieldType,required,autoFill,memo){
    return requests.post(soarData + '/reportConfig/field',{     
      teamKey:teamKey,
      fieldName:fieldName,
      fieldType:fieldType,
      required:required,
      autoFill:autoFill,
      memo:memo   
    });
   },
   //编辑要素
   editFactor(fieldKey, fieldName, fieldType,required,autoFill,memo){
    return requests.patch(soarData + '/reportConfig/field',{     
      fieldKey:fieldKey,
      fieldName:fieldName,
      fieldType:fieldType,
      required:required,
      autoFill:autoFill,
      memo:memo   
    });
   },
   //设置必要要素字段 fieldKey required
   editMustFactor(fieldKey,required){
    return requests.patch(soarData + '/reportConfig/field',{
      fieldKey,required
    });
   },
   //滚动到下期 fieldKey autoFill
   editAutoFillFactor(fieldKey,autoFill){
    return requests.patch(soarData + '/reportConfig/field',{
      fieldKey,autoFill
    });
   },
   //删除要素
   deleteFactor(fieldKey){
    return requests._delete(soarData + '/reportConfig/field',{     
      fieldKey:fieldKey,        
    });
   },
   //排序要素
   orderFactor(teamKey,fieldKeyArr){
    return requests.patch(soarData + '/reportConfig/field/order',{    
      teamKey:teamKey,
      fieldKeyArr:fieldKeyArr  
    }) 
  },
  //基本配置 
  getBaseInfo(teamKey){
    return requests.get(soarData + '/reportConfig',{    
      teamKey:teamKey,     
    }) 
  },
  //编辑基本配置
  //teamKey, dueTime, remindTime, reviewVisible, copyVisible, reportOpen
  editBaseInfo(param){
    return requests.patch(soarData + '/reportConfig',param) 
  }, 
  //初始化汇报内容
  getInitReportContet(teamKey,reportType){
    return requests.get(soarData + '/report/initialContent/new',{    
      teamKey:teamKey,
      reportType:reportType     
    }) 
  },
  //新增汇报 
  //teamKey, contentObj, reportType(day/week/month/year/total), copyUsers
  addReport(param){
    return requests.post(soarData + '/report',param) 
  }, 
  //teamKey, contentObj, reportType(day/week/month/year/total), reviewers, copyers
  addReportNew(param){
    return requests.post(soarData + '/report/new',param) 
  }, 
  
   //编辑汇报 reportKey,contentObj 
  editReport(param){
    return requests.patch(soarData + '/report',param) 
  },

   //删除汇报
   deleteReport(reportKey){
    return requests._delete(soarData + '/report',{    
      reportKey  
    })
  },

  //我的列表  reportType【日周月等】 reviewType【mine,review,copy】 
  getReportList(teamKey,reportType,reviewType,page,limit){
    return requests.get(soarData + '/report/review',{    
      teamKey,reportType,reviewType,page,limit     
    }) 
  },
  //审阅/抄送给我的 targetUserKey
  getNewReportList(teamKey,reportType,reviewType,targetUserKey,page,limit){
    return requests.get(soarData + '/report/review/v2',{    
      teamKey,reportType,reviewType,targetUserKey,page,limit     
    }) 
  },
  //汇报详情 fieldKey要素key 
  getReportViewdDetails(reportKey,fieldKey){
    return requests.get(soarData + '/report/detail',{    
      reportKey,fieldKey   
    }) 
  },
  //计划任务列表teamKey planTag 
  getTaskPlanList(teamKey,planTag){
    return requests.get(soarData + '/task/planList',{    
      teamKey,planTag   
    })
  },
  //汇报评分列表 
  getReportScoreList(reportKey){
    return requests.get(soarData + '/report/score',{    
      reportKey   
    })
  },
  //审阅汇报
  reviewReport(reportKey, score, memo){
    return requests.post(soarData + '/report/review',{    
      reportKey, score, memo   
    })
  },
 
  //获取留言列表
  getCommentList(reportKey,page,limit){
    return requests.get(soarData + '/report/comment',{
      reportKey,page,limit  
    }) 
  }, 
  //新增评论 reportKey, content
  //commentId:string(如果是子评论，需要传要回复的父评论key)
  addComment(param){
    return requests.post(soarData + '/report/comment',param) 
  }, 
  //删除评论
  deleteComment(commentKey){
    return requests._delete(soarData + '/report/comment',{
      commentKey            
    }) 
  },
  //我的计划任务列表 
  //planTag[''=全部 today=今日 tomorrow=明日 weekend=本周截止 monthend=本月截止 future=未来 ]
  getMyTaskPalnList(teamKey,planTag){
    return requests.get(soarData + '/task/planList',{
      teamKey,planTag 
    }) 
  },
  //我的文档 今日新增/编辑文档  
  getMyCardToday(teamKey){
    return requests.get(soarData + '/card/today',{
      teamKey
    }) 
  },

  //我审阅的 抄送给我的
  //关联汇报任务列表
  getReportTask(reportKey){
    return requests.get(soarData + '/report/task',{
      reportKey
    }) 
  },
  //关联汇报文档列表  list(type:create/update)
  getReportCard(reportKey,type){
    return requests.get(soarData + '/report/card',{
      reportKey,type
    }) 
  },
  //相关汇报列表
  getReportRelative(reportKey,reportType,reviewType){
    return requests.get(soarData + '/report/relative',{
      reportKey,reportType,reviewType
    }) 
  },
  //我审阅得成员列表  (fieldName:beReview/beCopy/beVisible)
  getReviewToMeMember(teamKey,fieldName){
    return requests.get(soarData + '/reportLine/member',{
      teamKey,fieldName
    })
  },
  //抄送给的成员列表 
  getCopyToMeMember(teamKey){
    return requests.get(soarData + '/reviewLine/copyToMe',{
      teamKey
    })
  },
  //获取全员汇报列表
  getAllReport(teamKey,memberKey,reportType,startTime,endTime,hasReview,page,limit){
    return requests.get(soarData + '/report/all',{
      teamKey,memberKey,reportType,startTime,endTime,hasReview,page,limit
    })
  },
  //汇报提醒 
  getReportRemind(teamKey){
    return requests.get(soarData + '/report/remind',{
      teamKey
    })
  },
  //审阅提醒
  getReviewRemind(teamKey,reportType){
    return requests.get(soarData + '/report/review/remind',{
      teamKey,reportType
    })
  },
  //检查补填汇报的日期
  getReportCheckDate(teamKey,reportType){
    return requests.get(soarData + '/report/checkBefore',{
      teamKey,reportType
    })
  },  
  //补填汇报 reportType(day/week/month/year/total)
  postReportBefore(teamKey, contentObj, reportType,reportDate,reportWeek,reportMonth,
    reportYear,copyUsers){
    return requests.post(soarData + '/report/before',{
      teamKey,contentObj,reportType,reportDate,reportWeek,reportMonth,
    reportYear,copyUsers
    })
  },
  //teamKey, contentObj, reportType,reportDate,reportWeek,reportMonth,
  // reportYear,reviewers, copyers
  postReportBeforeNew(param){
    return requests.post(soarData + '/report/before/new',param)
  },
  //部门树 organ tree,(showStaff:true显示员工，onlyOne:true只返回一级树)
  getDepartmentTree(departmentKey,showStaff,onlyOne){
    return requests.get(soarData + '/department/tree',{
      departmentKey,showStaff,onlyOne
    })
  },
 //获取空间详情
  getDepartmentDetails(teamKey){
    return requests.get(soarData + '/team/detail',{
      teamKey
    })
  },
  //路线table
  getLineTreeTable(teamKey,reviewerKey){
    return requests.get(soarData + '/reportLine',{
      teamKey,reviewerKey
    })
  },
  //编辑审批路线
  //teamKey, reviewerKey, departmentKey, memberKey, beReview, beCopy, beVisible
  editLineTree(param){
    return requests.patch(soarData + '/reportLine',param)
  } 
}


//eslint-disable-next-line
export default {
    auth, 
    home,
    common, 
    soar, 
    setToken: _token => {
        localStorage.setItem('token', _token);
        token = _token;
        // console.log('setToken-----------', token);
    },
};