import { combineReducers } from 'redux';
import common from './common';
import auth from './auth';
import home from './home';

// import web from './web'

export default combineReducers({
    common,
    auth,    
    home,       
});