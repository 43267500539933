import {
    ASYNC_START, 
    ASYNC_END,   
    CHANGE_PATHNAME_STATE, 
    GET_UPTOKEN,
    GET_PREVIEW,
    POST_QINIU, 
    GET_HEADER_TABKEY,
  
  
    GET_SIDEBARKEY,

    GET_LOADING
} from '../actions/app';

import util from '../services/Util'

const defaultState = {
    loading: false,
    waiting: false,  
    uptoken:'',//获取七牛token  
    roleItem:{0:'站长',1:'管理员',2:'编辑',3:'作者',4:'成员',5:'查看'},
    pathNameState:true, 
    payUrl:'https://account.qingtime.cn/pay',  
   //iconfont.cn引用图标
    iconFontUrl:'https://at.alicdn.com/t/c/font_4414428_mqznww7c8i.js',    
    previewSwitch:false, //默认是编辑模式   
    
    openFileInfo:false,
    nodesTypeArr: [
        {'name':"人物","value":1,},
        {'name':"事件","value":2,},
        {'name':"地点","value":3,},
        {'name':"作品","value":4,},
        {'name':"成就","value":5,},
        {'name':"文章","value":6,},
        {'name':"家谱","value":7,},
    ],
    nodeTypeObj:{1:"人物",2:"事件",3:"地点",4:"作品",5:"成就",6:"文章",7:"家谱"},
    timeExactArr:[
        {'name':"准确","value":1},
        {'name':"大约","value":2},
        {'name':"存疑","value":3}, 
    ],
    timeExactObj:{1:"准确",2:"大约",3:"存疑"},
    timeTypeArr:[
        {'name':"AD","value":"AD"},
        {'name':"BC","value":"BC"},
    ],
    // relationTypeObj:{1:"亲情",2:"爱情",3:"友情",4:"事业",5:"师从",6:"影响",7:"其他"},
    // relationDescObj:{1:"经历",2:"成就",3:"归属",4:"影响",5:"其他"},
    selectrelationAttr:[
        {'name':'人与人','sort':['亲情','爱情','友情','事业','师从','影响','其他']},
        {'name':'人与非人','sort':['经历','成就','归属','影响','其他']}, 
    ],
    nodesMould:"https://nodeserver.qingtime.cn/节点导入模板.xlsx",
    shipMould:"https://nodeserver.qingtime.cn/关系导入模板.xlsx",
    mapInfo:null,
    headerTabKey:util.common.getQueryString("reviewType") ? util.common.getQueryString("reviewType") : 'mine',//0我的 1审阅 2抄送 
    barKey: util.common.getQueryString("reportType") ? util.common.getQueryString("reportType") : 'day',
    reportKeys:'',
    LoadingFiles:false
};

const common = (state = defaultState, action) => {
    switch (action.type) {
        case ASYNC_START:
            return {
                ...state,
                loading: action.noLoading ? false : true,
                waiting: true,
            };
        case ASYNC_END:
            return {
                ...state,
                loading: false,
                waiting: false,
            };            
        case CHANGE_PATHNAME_STATE:                 
            return {
                ...state,
                [action.data.property]:action.data.state            
            };  
        //获取七牛的token            
        case GET_UPTOKEN:           
            if (!action.error) { 
                window.localStorage.setItem('upToken',action.payload.data)                
                return {
                    ...state,
                    uptoken: action.payload.data,
                                                       
                };                            
            } else{             
                return state;
            } 
        //post七牛文件
        case POST_QINIU:           
            if (!action.error) {  
                return {
                    ...state,
                    qiniuData: action.payload.data                                     
                };                            
            } else{             
                return state;
            }
       
        //预览打开
        case GET_PREVIEW:
            return {
                ...state,
                previewSwitch:action.previewSwitch
            } 
        case GET_HEADER_TABKEY:                
            return {
                ...state,
                headerTabKey:action.headerTabKey
            };     
       
        //获取侧边栏key
        case GET_SIDEBARKEY:
            return {
                ...state,
                barKey:action.barKey
            }  
      
        case GET_LOADING:
            return {
                ...state,
                LoadingFiles: action.LoadingFiles,            
            };        
        default:
            return state;
    }
};

export default common;
