import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Tabs } from "antd";
import {} from "../../actions/app";
// import util from "../../services/Util";
import ListItem from '../common/listItem'

const Water = () => {
//   const dispatch = useDispatch();
  // const history = useHistory();
  //   let token = util.common.getQueryString("token") || "";
//   const auth = useSelector((state) => state.auth);
//   const user = auth.user;

  return (
    <div className="homepage">
      <div className="goverment-box">
        <img src="/image/02.png" alt=""></img>
        <div className="container">
          <div className="works-cnt">
            <div className="buy-cnt">
                <h2 className="textAline">生态鱼产业链</h2>
                <div className="buy-tab-cnt"> 
                   <ListItem list={[1]}></ListItem>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Water;
