
import Home from '../components/home/home'
import Goverment from '../components/government/goverment'
import EnterPrise from '../components/enterprise/enterprise'
import News from '../components/news/news'
import NewsDetails from '../components/news/details'
import Works from '../components/works/works'
import Login from '../components/login/login'
import Register from '../components/register/register'
import ResetPassword from '../components/login/reset'
import Buy from '../components/buy/buy'
import Farming from '../components/farming/farming'
import Forestry from '../components/forestry/forestry'
import Water from '../components/water/water'
import Tour from '../components/tour/tour'
import Traffic from '../components/traffic/traffic'
import Economy from '../components/economy/economy'
import HomeList from '../components/home/list'
import HomePic from '../components/home/pic1'
import HomePic2 from '../components/home/pic2'

const routers = [     
    {
        path:'/',
        component:Home
    },
    {
        path:'/list',
        component:HomeList
    },
    {
        path:'/p1',
        component:HomePic
    },
    {
        path:'/p2',
        component:HomePic2
    },
    {
        path:'/login',
        component:Login
    },
    {
        path:'/register',
        component:Register
    },
    {
        path:'/reset',
        component:ResetPassword
    },
    {
        path:'/goverment',
        component:Goverment
    },
    {
        path:'/enterPrise',
        component:EnterPrise
    },
    {
        path:'/news',
        component:News
    },
    {
        path:'/newsDetails',
        component:NewsDetails
    },
    {
        path:'/worksDetails',
        component:NewsDetails
    },
    {
        path:'/works',
        component:Works
    },
    {
        path:'/buy',
        component:Buy
    },    
    {
        path:'/farming',
        component:Farming
    },
    {
        path:'/forestry',
        component:Forestry
    },
    {
        path:'/water',
        component:Water
    },
    {
        path:'/tour',
        component:Tour
    },    
    {
        path:'/traffic',
        component:Traffic
    },
    {
        path:'/economy',
        component:Economy
    },    

]
export default routers