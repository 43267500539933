import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import "./works.css";
import {} from "../../actions/app";
// import util from "../../services/Util";
import moment from "moment";
import "moment/locale/zh-cn";

const WorksPanel = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
//   let token = util.common.getQueryString("token") || "";
  const {type} = props
  const auth = useSelector((state) => state.auth);
  const user = auth.user;
  const [panelKey,setPanelKey] = useState([]) //面板key
  const [items,setItems] = useState([])
  
  const list=[
      {"name":"会计","_key":"11"},{"name":"大数据","_key":"22"}
  ]
  const details = {
      "11":{"name":"财务中心","place":"万安县","type":"金融类","number":2},
      "22":{"name":"大数据","place":"万安县","type":"护粮网","number":1}
  }

//   const items = [
//     {
//       key: '1',
//       label: 'This is panel header 1',
//       children: <p>{text}</p>,
//     },
//     {
//       key: '2',
//       label: 'This is panel header 2',
//       children: <p>{text}</p>,
//     },
//     {
//       key: '3',
//       label: 'This is panel header 3',
//       children:null,
//     },
//   ];

  const onChangePanelKey=(key)=>{
    // console.log('key---',key);
    setPanelKey(key)
  }

  useEffect(()=>{
      if(list.length){
        //   setPanelKey(list[0]._key)
         let arr = []
         list.forEach((item)=>{
            arr.push({"key":item._key,"label":item.name})         
         }) 
         setItems([...arr])       
      }
  },[])

  useEffect(()=>{
      if(panelKey.length && items.length){      
         let datas = Object.assign([], items)
         datas.forEach((item)=>{
             if(item.key === panelKey[0]){
             item.children = (<div className="panel-details">
                 <div>{details[panelKey[0]].name}</div>
                 <div>{details[panelKey[0]].place}</div>
                 <div>{details[panelKey[0]].type}</div>
                 <div>{details[panelKey[0]].number}</div>
                 <div>{moment().format("YYYY-MM-DD")}</div>
                 </div>)
             }
         }) 
         setItems([...datas])
      }
  },[panelKey])

  return (
    (items.length ?
    <Collapse 
      accordion 
      items={items} 
    //   defaultActiveKey={panelKey}
    //   activeKey={panelKey}
      expandIconPosition="end"
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      onChange={onChangePanelKey}
    />:null)
  );
};
export default WorksPanel;
